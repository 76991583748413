
import {Link} from "react-router-dom";
import React, {useEffect} from 'react';
import Compliance from "../assets/compliance.png";
import AOS from 'aos';
import JavascriptIcon from '../assets/sdk-support/js.png';
import AndroidIcon from '../assets/sdk-support/android.png'
import IOSIcon from '../assets/sdk-support/ios.png'
import WooIcon from '../assets/sdk-support/woo.png'
import MagentoIcon from '../assets/sdk-support/magento.png'
import ShopifyIcon from '../assets/sdk-support/shopify.png'
import PhpIcon from '../assets/sdk-support/php.png'
import NetIcon from '../assets/sdk-support/net.png'
import NodejsIcon from '../assets/sdk-support/nodejs.png'
import JavaIcon from '../assets/sdk-support/java.png'


const docsList = [{
        header: "Platform SDKs",
        data: [{title: "Web Integration", link: "/#javascript", logo: JavascriptIcon },
        {title: "Android SDK", link: "/#android", logo: AndroidIcon },
        {title: "IOS SDK", link: "/#ios", logo: IOSIcon },]
    },{
        header: "eCommerce Plugins",
        data: [{title: "WooCommerce", link: "/#woo-commerce", logo: WooIcon },
        {title: "Magento", link: "/#magento", logo: MagentoIcon },
        {title: "Shopify", link: "/#shopify", logo: ShopifyIcon },]
    },{
        header: "Server Integrations",
        data: [{title: "PHP", link: "/#php", logo: PhpIcon },
        {title: ".NET", link: "/#net", logo: NetIcon },
        {title: "NodeJs", link: "/#nodejs", logo: NodejsIcon },
        {title: "Java", link: "/#java", logo: JavaIcon }]
    }

]

function DocsPage() {
    useEffect(() => {
        AOS.init({
            duration: 1500
        });
    }, []);
    return (
        <div className="pb-16">
            <div className="bg-gray-100 bg-asset w-full h-auto text-gray-700 flex flex-col items-center justify-center py-16">
                <span className="px-5 md:px-0 text-center md:text-left text-3xl font-semibold">Integrations</span>
            </div>
            <div className="grid grid-cols-1 gap-5 px-40 py-20">
                {
                    docsList.map(({header, data}, index)=> (
                        <>
                            <h5 key={index} className="text-primary-theme font-semibold text-xl text-center">{header}</h5>
                            <div className="grid grid-cols-3 gap-10">
                                {data.map(({title, link, logo}, i)=> (
                                    <div key={i} className="card w-full h-auto bg-gray-50 flex flex-col pt-10 items-center shadow rounded border border-gray-100 hover:shadow-lg transition-shadow hover:transition-shadow">
                                        <img src={logo} alt="" width="100" className="rounded"/>
                                        <h5 className="w-full text-center py-2 mt-12 font-semibold border-t border-b border-gray-200">
                                            {title}
                                        </h5> 
                                        <div className="w-full py-4 px-4">
                                            <Link to={`${link}`} className="w-full py-3 font-semibold flex items-center justify-center bg-primary-theme rounded text-white text-sm">Exoplore Doc</Link>
                                        </div>      
                                    </div>
                                ))}
                            </div>
                        </>
                    ))
                }
            </div>
        </div>
    )
}

export default DocsPage
