import React from 'react'
import { Footer, Navbar } from '../components';
import ContactForm from '../components/ContactForm';

function Contact() {
    return (
        <div>
            <Navbar />
            <ContactForm/>
            <Footer />
        </div>
    )
}

export default Contact
