

import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {MenuIcon} from '../../assets/MenuIcon';
import Navbar from '../../components/documentation/Navbar.jsx';
import Sidebar from '../../components/documentation/Sidebar.jsx';
import SDKPage from '../../components/SDKPage';
import docContents from '../../components/documentation/content.json';
import {ArrowRight, ArrowLeft} from '../../assets/index';
import GetStartedImage from "../../assets/docs/get-started.png";
import Alert from '../../components/Alert/Alert';
import RightNav from '../../components/documentation/RightNav.jsx';


function TestCard() {
    const [activeTab, setActiveTab] = useState('0');
    const [paymentTab, setPaymentTab] =useState("payment-link");

    const contents = ["Test card information", "Supported Payment Methods"];
    

    const paymentProceedures={
        paymentLink: [
            "Login into your dashboard and navigate to Collect Payments > Payment Links.",
            "Click on Create new payment link.",
            "Select Single charge",
            "Fill in the required details, you can leave the amount section empty to allow your customers to enter the amount to be paid or input an amount so your customers pay a fixed amount as you've set. To add more features to the payment link, click on the customise your payment link option.",
            "We create your payment link and you can now copy and share with your customers!",
            "When your customers click on the link, the Flutterwave payment modal will be displayed for them to supply their payment details.",
            "We charge the provided payment details and credit you."
        ]
    }

    console.log(docContents);

    const setToActive = (e) => {
        setActiveTab(e.target.id);
    }
    return (
        <div> 
            <Navbar />
            <div className="w-full flex">
                <Sidebar/>
                <div className="documentation-container px-8 py-4 flex items-start">
                    <div className="content">
                        <h5 id={contents[0]} className="title w-full font-medium">Test card information</h5>
                        <p className="paragraph py-2">{"Use our test cards, any future expiry date, and any CVV to get test the transactions in the test environment."}</p>

                        <Alert>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 8V12" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 16H12.01" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span className="font-semibold">Note:</span> 
                            For Test Mode Only: You can use these test card to make payments in test mode only. Using these for live mode payments will result in failed transaction.
                        </Alert>

                        <p id={contents[1]} className="subTopic py-2">{"Supported Payment Methods"}</p>
                        

                        <div className="grid grid-cols-2">
                            <header className="w-full py-2 border-b border-gray-300">Card Type</header>
                            <header className="w-full border-l px-3 py-2 border-b border-gray-300">Method</header>
                        </div>
                        <div className="grid grid-cols-2">
                            <header className="w-full py-2 border-b border-gray-300">Visa</header>
                            <header className="w-full border-l px-3 py-2 border-b border-gray-300">
                                <p className="text-sm">4999 0821 0002 9373</p>
                                <p className="text-sm">​Expiry Date: 01/23</p>
                                <p className="text-sm">CVV: 126</p>
                                <p className="text-sm">Pin: 4792</p>
                                <p className="text-sm">Name: Test</p>
                            </header>
                        </div>
                        

                        <div className="grid grid-cols-2 gap-5">
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/web-integration"><ArrowLeft /></Link>
                                <Link to="/web-integration" className="flex flex-col">
                                    <p className="text-sm text-gray-500 text-right"><span className="text-gray-700">Previous</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium text-right">Web Integration</h5>
                                </Link>
                            </div>
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/get-started" className="flex flex-col">
                                    <p className="text-sm text-gray-500"><span className="text-gray-700">Next</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium">Getting started</h5>
                                </Link>
                                <Link to="/get-started"><ArrowRight /></Link>
                            </div>
                        </div>
                        
                    </div>
                    <RightNav contents={contents} />
                </div>
            </div>
        </div>
    )
}

export default TestCard;
