

import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {MenuIcon} from '../../assets/MenuIcon';
import Navbar from '../../components/documentation/Navbar.jsx';
import Sidebar from '../../components/documentation/Sidebar.jsx';
import SDKPage from '../../components/SDKPage';
import docContents from '../../components/documentation/content.json';
import {ArrowRight, ArrowLeft} from '../../assets/index';
import GetStartedImage from "../../assets/docs/get-started.png";
import Alert from '../../components/Alert/Alert';
import PL1 from '../../assets/docs/pl-1.png';
import PL2 from '../../assets/docs/pl-2.png';
import PL3 from '../../assets/docs/pl-3.png';
import PL4 from '../../assets/docs/pl-4.png';
import RightNav from '../../components/documentation/RightNav.jsx';


function PaymentLink() {
    const [activeTab, setActiveTab] = useState('0');
    const [paymentTab, setPaymentTab] =useState("payment-link");

    const contents = ["WayaQuick Payment Links", "How to create payment links", "How to create a one-time payment link", "How to create a recurrent payment link"];
    

    const paymentProceedures={
        paymentLink: [
            "Login into your dashboard and navigate to Collect Payments > Payment Links.",
            "Click on Create new payment link.",
            "Select Single charge",
            "Fill in the required details, you can leave the amount section empty to allow your customers to enter the amount to be paid or input an amount so your customers pay a fixed amount as you've set. To add more features to the payment link, click on the customise your payment link option.",
            "We create your payment link and you can now copy and share with your customers!",
            "When your customers click on the link, the Flutterwave payment modal will be displayed for them to supply their payment details.",
            "We charge the provided payment details and credit you."
        ]
    }

    console.log(docContents);

    const setToActive = (e) => {
        setActiveTab(e.target.id);
    }
    return (
        <div>
            <Navbar />
            <div className="w-full flex">
                <Sidebar/>
                <div className="documentation-container px-8 py-4 flex items-start">
                    <div className="content">
                        <h5 id={contents[0]} className="title w-full font-medium">WayaQuick Payment Links</h5>
                        <p className="paragraph py-2">{"One time payment Link and recurrent Link feature of WayaQuick are used to accept online payments without a website or application. This section will provide you with a detailed information about these features"}</p>

                        <p id={contents[1]} className="subTopic py-2">{"Payment Links"}</p>
                        <p className="paragraph py-2">{"Using Payments links is very easy. You can make a payment link and send it instantly from your merchant dashboard itself to the customer. It is a quick way of collecting payment, for which you do not even need a website or application."}</p>

                        <Alert>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 8V12" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 16H12.01" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span className="font-semibold">Note:</span> 
                            You need to have your account activated for creating payment links. 
                        </Alert>

                        <p id={contents[1]} className="subTopic py-2">{"How to create payment links"}</p>
                        <ul className="unorderedList">
                            <li className="py-1 listItem">{"Log In to your WayaQuick Dashboard"}</li>
                            <li className="py-1 listItem">{"‘Go to’ the Merchant Account > Payment Link"}</li>
                            <li className="py-1 listItem">{"Click on the ‘Create payment link’ tab"}</li>
                        </ul>

                        <img src={PL1} alt="" className="my-2"/>
                        <p id={contents[1]} className="subTopic py-2">{"Choose Link type"}</p>
                        <img src={PL2} alt="" className="my-2"/>
                        <p id={contents[1]} className="subTopic py-2">{"One-time payment link"}</p>
                        <img src={PL3} alt="" className="my-2"/>
                        <p id={contents[2]} className="subTopic py-2">{"How to create a one-time payment link"}</p>
                        
                        <p className="paragraph py-2">{"Step 1: Login into your dashboard and navigate to Collect Payments > Payment Links."}</p>
                        <p className="paragraph py-2">{"Step 2: Click on Create new payment link."}</p>
                        <p className="paragraph py-2">{"Step 3: Select Single charge"}</p>
                        <p className="paragraph py-2">{"Step 4: Fill in the required details, you can leave the amount section empty to allow your customers to enter the amount to be paid or input an amount so your customers pay a fixed amount as you've set. To add more features to the payment link, click on the customise your payment link option."}</p>
                        <p className="paragraph py-2">{"Step 5: We create your payment link and you can now copy and share with your customers!"}</p>
                        <p className="paragraph py-2">{"Step 6: When your customers click on the link, the Flutterwave payment modal will be displayed for them to supply their payment details."}</p>
                        <p className="paragraph py-2">{"Step 7: We charge the provided payment details and credit you."}</p>


                        <p id={contents[5]} className="subTopic py-2">{"Recurrent payment link"}</p>
                        <img src={PL4} alt="" className="my-2"/>

                        <p id={contents[3]} className="subTopic py-2">{"How to create a recurrent payment link"}</p>
                        <p className="paragraph py-2">{"Step 1: Login into your dashboard and navigate to Collect Payments > Payment Links."}</p>
                        <p className="paragraph py-2">{"Step 2: Click on create new payment link."}</p>
                        <p className="paragraph py-2">{"Step 3: Select recurring charge"}</p>
                        <p className="paragraph py-2">{"Step 4: Fill in the required details, you can leave the amount section empty to allow your customers enter the amount to be paid or input an amount so your customer's pay a fixed amount. You can add Interval which is when the customer should be debited e.g. Weekly, monthly, and how many times to charge the customer before stopping the subscription automatically, if not filled the customer would be charged infinitely except you or the customer cancels the subscription. To add more features to the payment link, click on the customise your payment link option."}</p>
                        <p className="paragraph py-2">{"Step 5: We would create your payment link and you can now copy and share with your customers!"}</p>
                                                

                        <div className="grid grid-cols-2 gap-5">
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/merchant-dashboard"><ArrowLeft /></Link>
                                <Link to="/merchant-dashboard" className="flex flex-col">
                                    <p className="text-sm text-gray-500 text-right"><span className="text-gray-700">Previous</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium text-right">WayaQuick Merchant Dashboard</h5>
                                </Link>
                            </div>
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/dev-get-started" className="flex flex-col">
                                    <p className="text-sm text-gray-500"><span className="text-gray-700">Next</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium">Getting started</h5>
                                </Link>
                                <Link to="/dev-get-started"><ArrowRight /></Link>
                            </div>
                        </div>
                        
                    </div>
                    <RightNav contents={contents} />
                </div>
            </div>
        </div>
    )
}

export default PaymentLink;
