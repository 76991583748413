

import React from 'react';
import { Footer, Navbar } from '../components';
import PCIPage from '../components/PCIPage';


function PCI() {
    return (
        <div>
            <Navbar />
            <PCIPage/>
            <Footer />
        </div>
    )
}

export default PCI
