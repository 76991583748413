
import {Link} from "react-router-dom";
import React, {useEffect} from 'react';
import Compliance from "../assets/compliance.png";
import AOS from 'aos';

function PCIPage() {
    useEffect(() => {
        AOS.init({
            duration: 1500
        });
    }, []);
    return (
        <div className="pb-16">
            <div className="bg-primary-theme w-full h-auto text-white flex flex-col items-center justify-center py-10">
                <span className="px-5 md:px-0 text-center md:text-left text-3xl font-semibold">Compliance at WayaQuick</span>
                <p className="w-full px-5 md:w-1/4 m-auto text-center">WayaQuick keeps up with the best expectations of consistency conceivable in the locales inside which we work</p>
            </div>
            <div className="flex flex-col py-8 px-8 md:px-0">
                <div className="w-full overflow-x-hidden h-auto md:h-auto grid grid-cols-1 md:grid-cols-2 md:pb-0 pt-12">
                    <div className="h-auto flex w-full justify-center md:justify-end items-start mb-8">
                        <img data-aos="fade" src={Compliance} alt="product_rocket" className="mx-8 md:mr-20 w-full md:w-3/5 mt-10 ..." />
                    </div>
                    <div className="w-full md:w-4/5 md:pr-10 flex flex-col items-start justify-center pb-5 ...">
                        <h4 data-aos="fade" className="title md:text-3xl md:mt-0 font-semibold">WayaQuick is PCI DSS Level 1 Certified.</h4>
                        <p className="w-full ...">WayaQuick has been audited by an independent PCI Qualified Security Assessor (QSA) and we're PCI DSS 3.2 compliant as a Level 1 Service Provider (loosely referred to as PCI Level 1 compliance).
                        <br/><br/>
                        This is the most noteworthy, most severe degree of affirmation conceivable in the worldwide payments industry.</p>
                        <ul className="mt-5">
                            <li data-aos="fade" data-aos-delay="300" className="flex items-center gap-3 my-4">
                                <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="35" height="33" rx="10" fill="#FFECE6" />
                                    <path d="M25 11L14 22L9 17" stroke="#FF6700" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <p className="w-full ...">WayaQuick on the Visa PCI DSS-certified service provider database</p>
                            </li>
                            <li data-aos="fade" data-aos-delay="400" className="flex items-center gap-3 my-4">
                                <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="35" height="33" rx="10" fill="#FFECE6" />
                                    <path d="M25 11L14 22L9 17" stroke="#FF6700" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <p className="w-full text-md ...">WayaQuick on the Mastercard Payment Facilitator database (select the Middle East/Africa tab and navigate to "WayaQuick").</p>
                            </li>
                            <li data-aos="fade" data-aos-delay="500" className="flex items-center gap-3 my-4">
                                <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="35" height="33" rx="10" fill="#FFECE6" />
                                    <path d="M25 11L14 22L9 17" stroke="#FF6700" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <p className="w-full ...">WayaQuick possesses a Payment System Service Providers (PSSP) Commercial Licence from the Central Bank of Nigeria</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="py-5">
                <h4 className="text-2xl md:text-3xl mb-8 text-center font-semibold text-primary-theme">Get started now to develop your business.</h4>

                <div className="flex justify-center items-center gap-3">
                    <button className="bg-primary-theme rounded uppercase text-white flex items-center font-semibold text-sm px-5 py-3">
                    Register now
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="white"/>
                    </svg>

                    </button>
                    <button className="bg-white shadow rounded uppercase text-primary-theme flex items-center font-semibold text-sm px-5 py-3">
                    EXPLORE DOC
                    </button>
                </div>
            </div>
        </div>
    )
}

export default PCIPage
