

import React from 'react';
import { Footer, Navbar } from '../components';
import SDKPage from '../components/SDKPage';


function SDK() {
    return (
        <div>
            <Navbar />
            <SDKPage/>
            <Footer />
        </div>
    )
}

export default SDK
