import React from 'react';
import FraudRuleSection from './FraudRuleSection';

function ProductFraudPage() {
    return (
        <div className="pb-16">
            <div className="bg-primary-theme w-full h-24 text-white flex items-center justify-center text-3xl font-semibold mb-10">
            Fraud Rules
            </div>
            <FraudRuleSection 
                title="Multiple (3 times) transactions from Same IP within one hour - Either with same email or different emails, Same device signature or different device signatures, same pan or different pan. "
                description="All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number). After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="Blocking based on transaction responses. Responses with high fraud scores e.g Insufficient balance, pick up card, do not honor, transaction not permitted to card holder. (Depending on fraud score could be blocked on first attempt if accumulated score for last 3 transactions is greater than 50). "
                description="All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="Email Check for multiple transactions (3x) within one hour - Either with same pan, different pans, same device signatures or different device signatures, same IP or different IP addresses. "
                description="All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="Device Signature block for multiple transactions (3x) within one hour - Either with same pan, different pans,  same device signatures or different device signatures, same IP or different IP address. "
                description="All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="Card Pan block for multiple transactions (3x) - Same pan used 3x within one hour - Either with same pan, different pans, same device signatures or different device signatures, same IP or different IP address. "
                description="All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="Insufficient Balance decline response with subsequent attempted lower transaction amount. "
                description="All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="Insufficient Balance for multiple transactions (3x attempts within an hour) "
                description="All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="Multiple (5 times) transactions from Same IP, Same email, Same device signature, Same Pan successful or failed within a Period of 1 hour "
                description="All details used are initially blocked for 1 hour. (Email, device signature, Pan used, IP, phone number).  After suspension has been lifted, if the same rule is flaunted again, the previous suspension time is multiplied by 2 and subsequently. Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="3 attempts within 30 minutes on same email, card PAN or device ID where  at least one reason for failure is insufficient funds "
                description="1 hour suspension of device. Email should not be suspended. Display warning message to cardholder. Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="Further attempts within 1 hour on same email, card PAN or device ID where at least one more reason for failure is insufficient funds "
                description="3-hour suspension of device, card PAN & Email. Display warning message to cardholder. Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="3 attempts within 30 minutes on same email, card PAN or device ID where at least one reason for failure is Stolen Card/Pick Up Card. "
                description="Blocking of device ID and Email. Display warning message to cardholder.  Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="3 attempts within 30 minutes on same email, card PAN or device ID where at least one reason for failure is Fraud Risk. "
                description="Suspension of device ID, Card PAN & Email for 15 minutes. Display warning message to cardholder.  Report to Admin Notification Page for further review. "
            />

            <FraudRuleSection 
                title="Where there are attempts to perform transactions on a merchants account by customers with disposable email address. "
                description="Transaction is declined and email address blocked. Report to Admin Notification Page for further review. "
            />
        </div>
    )
}

export default ProductFraudPage
