import React from 'react'

function Button({text, nextHandlerTo, id, actionHandler}) {
    return (
        <div className="px-0 pt-5 pb-6 w-full ...">
            {
                actionHandler
                ?
                <button onClick={() => actionHandler()} className="bg-primary-theme text-white text-sm rounded py-4 w-full flex items-center justify-center">{text}</button>
                :
                nextHandlerTo
                ?
                <button onClick={()=> id ? nextHandlerTo(parseInt(id)+1) : ''} className="bg-primary-theme text-white text-sm rounded py-4 w-full flex items-center justify-center">{text}</button>
                :
                <button className="bg-primary-theme text-white text-sm rounded py-4 w-full flex items-center justify-center">{text}</button>
            }
        </div>
    )
}

export default Button
