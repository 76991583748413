

import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {MenuIcon} from '../../assets/MenuIcon';
import Navbar from '../../components/documentation/Navbar.jsx';
import Sidebar from '../../components/documentation/Sidebar.jsx';
import SDKPage from '../../components/SDKPage';
import docContents from '../../components/documentation/content.json';
import {ArrowRight, ArrowLeft} from '../../assets/index';
import GetStartedImage from "../../assets/docs/get-started.png";
import RightNav from '../../components/documentation/RightNav.jsx';


function Payment() {
    const [activeTab, setActiveTab] = useState('0');
    const [paymentTab, setPaymentTab] =useState("payment-link");

    const contents = ["Payments", "Different Payment Methods", "Supported Payment Methods", "Types of Transactions"];
    

    const paymentProceedures={
        paymentLink: [
            "Login into your dashboard and navigate to Collect Payments > Payment Links.",
            "Click on Create new payment link.",
            "Select Single charge",
            "Fill in the required details, you can leave the amount section empty to allow your customers to enter the amount to be paid or input an amount so your customers pay a fixed amount as you've set. To add more features to the payment link, click on the customise your payment link option.",
            "We create your payment link and you can now copy and share with your customers!",
            "When your customers click on the link, the Flutterwave payment modal will be displayed for them to supply their payment details.",
            "We charge the provided payment details and credit you."
        ]
    }

    console.log(docContents);

    const setToActive = (e) => {
        setActiveTab(e.target.id);
    }
    return (
        <div> 
            <Navbar />
            <div className="w-full flex">
                <Sidebar/>
                <div className="documentation-container px-8 py-4 flex items-start">
                    <div className="content">
                        <h5 id={contents[0]} className="title w-full font-medium">Payments</h5>
                        <p className="paragraph py-2">{"WayaQuick online payments are diversified and can be well understood by the types of payment method options supported by it. Also, it becomes essential to know the types of payment transactions in order to understand what went wrong when the transactions were not a success or what is the meaning of the specific type of transaction."}</p>

                        <p id={contents[1]} className="subTopic py-2">{"Different Payment Methods"}</p>
                        <p className="paragraph py-2">{"The customers can be given the various payment options with the gateway checkout. They are allowed to pay with whichever payment method they want from Debit Cards-Credit Cards (Master Card, Visa, Verve, America Express, Union Pay, Discover, RuPay etc ), Wallets, USSD, PayAttitude etc. The customer’s dropping off at the checkout is mainly due to non-availability of their preferred payment option or an alternative payment option. They would need an alternate payment method if any of the other is facing down time. At that time, the customer would leave the site without making the payment. So it is always best if they can pay in any other way. For example, if Debit Card faces any issues then the customer could pay with the wallet."}</p>

                        <p id={contents[2]} className="subTopic py-2">{"Supported Payment Methods"}</p>

                        <div className="grid grid-cols-2">
                            <header className="w-full py-2 border-b border-gray-300">Payment Method</header>
                            <header className="w-full border-l px-3 py-2 border-b border-gray-300">Method</header>
                        </div>
                        <div className="grid grid-cols-2">
                            <header className="w-full py-2 border-b border-gray-300">Debit card - Credit Card</header>
                            <header className="w-full border-l px-3 py-2 border-b border-gray-300">
                                <p className="text-sm">All types of Cards like:</p>
                                <p className="text-sm">MasterCard</p>
                                <p className="text-sm">Visa</p>
                                <p className="text-sm">AMEX</p>
                                <p className="text-sm">Diners Cl</p>
                                <p className="text-sm">Diners Club</p>
                                <p className="text-sm">RuPay</p>
                            </header>
                        </div>
                        <div className="grid grid-cols-2">
                            <header className="w-full py-2 border-b border-gray-300">Wallet</header>
                            <header className="w-full border-l px-3 py-2 border-b border-gray-300">
                                <p className="text-sm">Login to pay</p>
                                <p className="text-sm">Scan to pay</p>
                            </header>
                        </div>
                         <div className="grid grid-cols-2">
                            <header className="w-full py-2 border-b border-gray-300">USSD</header>
                            <header className="w-full border-l px-3 py-2 border-b border-gray-300">
                                <p className="text-sm">Commercial Banks in Nigeria</p>
                            </header>
                        </div>
                         <div className="grid grid-cols-2">
                            <header className="w-full py-2 border-b border-gray-300">PayAttitude</header>
                            <header className="w-full border-l px-3 py-2 border-b border-gray-300">
                                <p className="text-sm">PayAttitude</p>
                                <p className="text-sm">Commercial Banks in Nigeria</p>
                            </header>
                        </div>


                        <p id={contents[3]} className="subTopic py-2">{"Types of Transactions"}</p>
                        <p className="paragraph py-2">{"This is important for the merchant to know the meaning of the status of the transaction. These labels can be viewed against each payment record in the merchant dashboard. According to the payment status, the customer can be approached. The best part is that the merchant knows the exact reason for the failed transaction."}</p>

                        <p className="paragraph py-2 font-semibold">{"1. Initialized"}</p>
                        <p className="paragraph py-2">{"This type of status for the transaction is shown when the customer opens the checkout page to make the payment but does not fill any further required information right from the name to payment details and he does not proceed further with the transaction."}</p>

                        <p className="paragraph py-2 font-semibold">{"2. Success"}</p>
                        <p className="paragraph py-2">{"After filling the payment details when the customer proceeds with the payment and the payment authorization also becomes successful then the funds are deducted from the customer’s bank. Then the transaction shows success."}</p>

                        <p className="paragraph py-2 font-semibold">{"3. Failed"}</p>
                        <p className="paragraph py-2">{"This is shown when there are some issues in authorizing the payment or if the customer drops out of the transaction due to some reason. There can be various other reasons for the failed transaction."}</p>

                        <p className="paragraph py-2 font-semibold">{"4. Abandoned"}</p>
                        <p className="paragraph py-2">{"When the customer starts to fill up the payment details but close the payment page due to some reason then the transaction shows as Not Attempted."}</p>

                        <p className="paragraph py-2 font-semibold">{"5. Partially Refunded"}</p>
                        <p className="paragraph py-2">{"The customized amount was refunded to the customer by the merchant from his merchant dashboard."}</p>

                        <p className="paragraph py-2 font-semibold">{"6. Fully Refunded"}</p>
                        <p className="paragraph py-2">{"Merchant has refunded the full amount to the customer from the Dashboard."}</p>

                        <p className="paragraph py-2 font-semibold">{"7. Chargeback"}</p>
                        <p className="paragraph py-2">{"The dispute was raised with the bank for the request for a refund due to fraud/dissatisfaction of services."}</p>

                        <p className="paragraph py-2 font-semibold">{"8. Dispute"}</p>
                        <p className="paragraph py-2">{"The dispute was raised on WayaQuick Dispute Resolution Center due to fraud/dissatisfaction of services."}</p>


                        <div className="grid grid-cols-2 gap-5">
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/payment-life-cycle"><ArrowLeft /></Link>
                                <Link to="/payment-life-cycle" className="flex flex-col">
                                    <p className="text-sm text-gray-500 text-right"><span className="text-gray-700">Previous</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium text-right">Payment Life Cycle</h5>
                                </Link>
                            </div>
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/merchant-dashboard" className="flex flex-col">
                                    <p className="text-sm text-gray-500"><span className="text-gray-700">Next</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium">WayaQuick Merchant Dashboard</h5>
                                </Link>
                                <Link to="/merchant-dashboard"><ArrowRight /></Link>
                            </div>
                        </div>
                        
                    </div>
                    <RightNav contents={contents} />
                </div>
            </div>
        </div>
    )
}

export default Payment;
