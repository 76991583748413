

import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {MenuIcon} from '../../assets/MenuIcon';
import Navbar from '../../components/documentation/Navbar.jsx';
import Sidebar from '../../components/documentation/Sidebar.jsx';
import SDKPage from '../../components/SDKPage';
import docContents from '../../components/documentation/content.json';
import {ArrowRight, ArrowLeft} from '../../assets/index';
import GetStartedImage from "../../assets/docs/get-started.png";
import Alert from '../../components/Alert/Alert';
import TE1 from '../../assets/docs/test-env1.png';
import RightNav from '../../components/documentation/RightNav.jsx';

function TestEnv() {
    const [activeTab, setActiveTab] = useState('0');
    const [paymentTab, setPaymentTab] =useState("payment-link");

    const contents = ["Test Environment"];
    

    const paymentProceedures={
        paymentLink: [
            "Login into your dashboard and navigate to Collect Payments > Payment Links.",
            "Click on Create new payment link.",
            "Select Single charge",
            "Fill in the required details, you can leave the amount section empty to allow your customers to enter the amount to be paid or input an amount so your customers pay a fixed amount as you've set. To add more features to the payment link, click on the customise your payment link option.",
            "We create your payment link and you can now copy and share with your customers!",
            "When your customers click on the link, the Flutterwave payment modal will be displayed for them to supply their payment details.",
            "We charge the provided payment details and credit you."
        ]
    }

    console.log(docContents);

    const setToActive = (e) => {
        setActiveTab(e.target.id);
    }
    return (
        <div>
            <Navbar />
            <div className="w-full flex">
                <Sidebar/>
                <div className="documentation-container px-8 py-4 flex items-start">
                    <div className="content">
                        <h5 id={contents[0]} className="title w-full font-medium">Test Environment</h5>
                        <p className="paragraph py-2">{"Test mode is a tool which allows you to simulate the actual payment process as if you were using it as live payment gateway."}</p>

                        <Alert>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 8V12" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 16H12.01" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div>
                              <span className="font-semibold">Note:</span> 
                              that test mode is only for development and testing purposes do not use it on a production site.
                            </div>
                        </Alert>

                        <p className="paragraph py-2">{"In order to activate the test Mode, first, follow the below-given steps"}</p>

                        <ul className="unorderedList">
                            <li className="py-1 listItem">{"Log In to your WayaQuick Dashboard"}</li>
                            <li className="py-1 listItem">{"Locate the live toggle at the nav bar, by defaut it will be at live mode and if you have already gone live, you can set it to test mode."}</li>
                            <li className="py-1 listItem">{"Copy your Merchant ID which you will locate ait at the top left corner"}</li>
                            <li className="py-1 listItem">{"To Generate API Keys, go to the Setting."}</li>
                            <li className="py-1 listItem">{"Then go to the WayaQuick setting > API keys and webhooks."}</li>
                            <li className="py-1 listItem">{"Click on Generate API Key (web or device as per your platform) and download your Access Token and Encryption Key(API Secret Key)."}</li>
                        </ul>

                        <img src={TE1} alt="" className="my-6"/>
                        
                        <Alert>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 8V12" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 16H12.01" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div>
                              <span className="font-semibold">Note:</span> 
                              that test mode is only for development and testing purposes do not use it on a production site.
                            </div>
                        </Alert>                     

                        <div className="grid grid-cols-2 gap-5">
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/dev-get-started"><ArrowLeft /></Link>
                                <Link to="/dev-get-started" className="flex flex-col">
                                    <p className="text-sm text-gray-500 text-right"><span className="text-gray-700">Previous</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium text-right">Getting Started</h5>
                                </Link>
                            </div>
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/api-encryption" className="flex flex-col">
                                    <p className="text-sm text-gray-500"><span className="text-gray-700">Next</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium">API Encryption Key Guide</h5>
                                </Link>
                                <Link to="/api-encryption"><ArrowRight /></Link>
                            </div>
                        </div>
                        
                    </div>
                    <RightNav contents={contents} />
                </div>
            </div>
        </div>
    )
}

export default TestEnv;
