

import React from 'react';
import {Link} from 'react-router-dom';
// import Logo from '../../assets/wayapay.svg';
import Logo from '../../assets/newLogo.png';


function Navbar() {
    return (
        <nav className="w-full sticky top-0 bg-white h-20 flex items-center justify-between px-8 shadow">
            <Link to="/" className="">
                <img src={Logo} alt="" className="" width="150px" height="50px" />
            </Link>
            <div className="relative">
                <span className="absolute w-5 flex left-3 top-3">
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.78893 15.2224C12.7163 15.2224 15.9001 12.0386 15.9001 8.11119C15.9001 4.18379 12.7163 1 8.78893 1C4.86152 1 1.67773 4.18379 1.67773 8.11119C1.67773 12.0386 4.86152 15.2224 8.78893 15.2224Z" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M17.6773 16.9995L13.8105 13.1328" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </span>
                <input className="w-64 px-10 h-10 rounded border border-gray-200 placeholder:text-red-400 text-gray-500" placeholder="search documentation here" />
            </div>
        </nav>
    )
}

export default Navbar
