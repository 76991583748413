import React from 'react';

function Card({ logo, description, name, link }) {
    return (
        <div className="w-full flex flex-col items-center py-8 border border-gray-200">
            {logo}
            <p className="text-md px-8 mt-4 text-center text-gray-600">{description}</p>
            <a href={link} rel="noreferrer" target="_blank">
                <button className="py-3 mt-4 px-5 text-sm bg-primary-theme rounded text-white">Explore {name}</button>
            </a>
        </div>
    )
}

export default Card
