

import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {MenuIcon} from '../assets/MenuIcon';
import Navbar from '../components/documentation/Navbar.jsx';
import Sidebar from '../components/documentation/Sidebar.jsx';
import SDKPage from '../components/SDKPage';
import docContents from '../components/documentation/content.json';
import {ArrowRight} from '../assets/index';


function Docs() {
    const [activeTab, setActiveTab] = useState('0');
    const [paymentTab, setPaymentTab] =useState("payment-link");
    const contents = ["Get started", "Introduction to WayaQuick", "Register to WayaQuick", "Methods of accepting payments"];
    

    const paymentProceedures={
        paymentLink: [
            "Login into your dashboard and navigate to Collect Payments > Payment Links.",
            "Click on Create new payment link.",
            "Select Single charge",
            "Fill in the required details, you can leave the amount section empty to allow your customers to enter the amount to be paid or input an amount so your customers pay a fixed amount as you've set. To add more features to the payment link, click on the customise your payment link option.",
            "We create your payment link and you can now copy and share with your customers!",
            "When your customers click on the link, the Flutterwave payment modal will be displayed for them to supply their payment details.",
            "We charge the provided payment details and credit you."
        ]
    }

    const paymentProceedures2 = {
        paymentLink: [
            "Step 1: Login into your dashboard and navigate to Collect Payments > Payment Links.",
            "Step 2: Click on create new payment link.",
            "Step 3: Select recurring charge",
            "Step 4: Fill in the required details, you can leave the amount section empty to allow your customers enter the amount to be paid or input an amount so your customer's pay a fixed amount. You can add Interval which is when the customer should be debited e.g. Weekly, monthly, and how many times to charge the customer before stopping the subscription automatically, if not filled the customer would be charged infinitely except you or the customer cancels the subscription. To add more features to the payment link, click on the customise your payment link option.",
            "Step 5: We would create your payment link and you can now copy and share with your customers!"
        ]
    }

    console.log(docContents);

    const setToActive = (e) => {
        setActiveTab(e.target.id);
    }
    return (
        <div>
            <Navbar />
            <div className="w-full flex">
                <Sidebar/>
                <div className="documentation-container px-8 py-4 flex items-start">
                    <div className="content">
                        <h5 className="title w-full font-medium">{docContents.title}</h5>
                        {
                            docContents.content.map(({subTitle, paragraph, unorderedLists, paragraph2}, index)=> (
                                <>
                                    {subTitle && <p id={contents[index]} className="subTopic py-2">{subTitle}</p>}
                                    {
                                        paragraph?.map(p=> (
                                            <p className="paragraph py-2">{p}</p>
                                        ))
                                    }
                                    <ul className="unorderedList">
                                        {
                                            unorderedLists?.map(li=> (
                                                <li className="py-1 listItem">{li}</li>
                                            ))
                                        }
                                    </ul>
                                    {
                                        paragraph2?.map(p=> (
                                            <p className="paragraph py-2">{p}</p>
                                        ))
                                    }
                                </>
                            ))
                        }


                        <div className="payment-acceptance-tab-container">
                            <div className="tab-header flex items-center">
                                <button onClick={(e)=> setPaymentTab("payment-link")} className={`${paymentTab==="payment-link" ? "bg-white" : "bg-gray-100"} w-auto h-12 border border-gray-200 text-sm px-3`}>Payments Links</button>
                                <button onClick={(e)=> setPaymentTab("payment-mobile")} className={`${paymentTab==="payment-mobile" ? "bg-white" : "bg-gray-100"} w-auto h-12 border border-gray-200 text-sm px-3`}>Using mobile application</button>
                                <button onClick={(e)=> setPaymentTab("payment-website")} className={`${paymentTab==="payment-website" ? "bg-white" : "bg-gray-100"} w-auto h-12 border border-gray-200 text-sm px-3`}>Using website</button>
                            </div>
                            <div className="w-full h-auto py-5 px-3 border border-gray-200">
                                {
                                    paymentTab==="payment-link" && (
                                        <>
                                            <p className="paragraph  text-sm text-gray-500">Payment links allow merchants to accept payments on their site without the need to integrate. This is great for Individuals and merchants who don't have developer resources. You can generate a payment link by visiting the Payment Links section on your dashboard. WayaQuick allows you to create payment links to collect one-time and recurring payments from your customers. Let's explain how below:</p>

                                            <h5 className="subTopic text-lg font-medium py-3 flex">Creating a one-time charge payment link</h5>
                                            {
                                                paymentProceedures.paymentLink.map((response, index)=> (
                                                    <div className="flex items-start w-full text-sm text-gray-500">
                                                        <p className="text-gray-500 leading-6 block"><span className="font-semibold mr-2">Step {index+1}:</span> {response} </p>
                                                    </div>
                                                ))
                                            }

                                            <h5 className="subTopic text-lg font-medium py-3 flex">Creating a recurring charge payment link</h5>
                                            {
                                                paymentProceedures2.paymentLink.map((response, index)=> (
                                                    <div className="flex items-start w-full text-sm text-gray-500">
                                                        <p className="text-gray-500 leading-6 block"><span className="font-semibold mr-2">Step {index+1}:</span> {response} </p>
                                                    </div>
                                                ))
                                            }
                                        </>
                                    )
                                }{
                                    paymentTab==="payment-mobile" && (
                                        <>
                                            <p className="paragraph  text-sm text-gray-500">You can accept online payments using your mobile with our available SDKs for Android as well as iOS. You can integrate these SDKs with your app and start accepting payments in no time. You can visit our Technical Documentation Guide and get further information regarding SDKs and how to integrate them.</p> 
                                            <p className="text-sm text-gray-500">If you have an application with any other technology or if you are facing any issue in integrating the SDKs in your application then please reach out to our tech support at <a href="mailto:it@WayaQuick.com" className="text-blue-500">it@WayaQuick.com</a>.</p>
                                        </>
                                    )
                                }{
                                    paymentTab==="payment-website" && (
                                        <>
                                            <p className=" text-sm text-gray-500 my-2">You can collect payments using your business website through various technical solutions like Integration kit for different languages, JS Checkout integration, Plug-Ins for different applications like Magento, WooCommerce, Shopify and many more.</p>
                                            <p className=" text-sm text-gray-500 my-2">We are also providing a guide using which you can integrate our PG with your website in case we don't have an integration kit readily available.</p>
                                            <p className=" text-sm text-gray-500 my-2">Visit our <Link to="/plugin-sdk" className="text-blue-500">Technical Documentation Guide</Link> for more information.</p> 
                                            <p className="text-sm text-gray-500 my-2">Reach out to our technical support at <a href="mailto:it@WayaQuick.com" className="text-blue-500 mx-1">it@WayaQuick.com</a> if you are facing any issue in integrating WayaQuick with your website.</p>

                                        </>
                                    )
                                }
                            </div>
                        </div>

                        <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                            <Link to="/registration-guide" className="flex flex-col">
                                <p className="text-sm text-gray-500"><span className="text-gray-700">Next</span> - WayaQuick Basic know-How</p>
                                <h5 className="text-gray-700 text-lg font-medium">Registraition Guide</h5>
                            </Link>
                            <Link to="/registration-guide"><ArrowRight /></Link>
                        </div>
                        
                    </div>
                    <div className="sticky top-28 pl-14">
                        <ul className="border-l border-gray-300 flex flex-col gap-3">
                            <p className="list-none px-5 uppercase flex items-center gap-3">
                                <MenuIcon />
                                CONTENTS
                            </p>
                            {
                                contents.map((content, index)=> (
                                    <li key={index} className="flex">
                                        <a id={index} onClick={setToActive} href={`#${content}`} className={`${activeTab === JSON.stringify(index) ? "text-primary-theme border-l-4 border-primary-theme -ml-0.5": "text-gray-700"} pl-4 text-sm  font-medium`}>{content}</a>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Docs
