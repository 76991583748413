

import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {MenuIcon} from '../../assets/MenuIcon';
import Navbar from '../../components/documentation/Navbar.jsx';
import Sidebar from '../../components/documentation/Sidebar.jsx';
import SDKPage from '../../components/SDKPage';
import docContents from '../../components/documentation/content.json';
import {ArrowRight, ArrowLeft, AccordionIcon, PlusIcon, MinusIcon} from '../../assets/index';
import GetStartedImage from "../../assets/docs/get-started.png";
import RightNav from '../../components/documentation/RightNav.jsx';


function GetStarted() {
    const [activeTab, setActiveTab] = useState('0');
    const [paymentTab, setPaymentTab] =useState("payment-link");
    const [activeAccordion, setActiveAccordion] = useState("get-started");

    const contents = ["Get started", "General Payment Flow with API", "Authorization","Types of Integration"];
    

    const paymentProceedures={
        paymentLink: [
            "Login into your dashboard and navigate to Collect Payments > Payment Links.",
            "Click on Create new payment link.",
            "Select Single charge",
            "Fill in the required details, you can leave the amount section empty to allow your customers to enter the amount to be paid or input an amount so your customers pay a fixed amount as you've set. To add more features to the payment link, click on the customise your payment link option.",
            "We create your payment link and you can now copy and share with your customers!",
            "When your customers click on the link, the Flutterwave payment modal will be displayed for them to supply their payment details.",
            "We charge the provided payment details and credit you."
        ]
    }

    const setToActive = (e) => {
        setActiveTab(e.target.id);
    }
    return (
        <div>
            <Navbar />
            <div className="w-full flex">
                <Sidebar/>
                <div className="documentation-container px-8 py-4 flex items-start">
                    <div className="content">

                        <div id={contents[0]} onClick={()=> setActiveAccordion("get-started")} className={`w-ful ${activeAccordion==="get-started" ? "h-auto": "h-20"} p-6 rounded cursor-pointer border border-gray-300 overflow-hidden my-5`}>
                            <h5 className="flex items-center justify-between gap-1"><span className="flex items-start gap-1"><AccordionIcon/> Get started</span>  {
                                activeAccordion==="get-started" ? <MinusIcon /> : <PlusIcon/>
                            } </h5>

                            {
                                activeAccordion === "get-started" && (
                                    <div className="mt-5">
                                        <p className="paragraph py-2">{"You can integrate WayaQuick on your website or application using various Integration kits available, according to the programming language or the web hosting platform. Here you will find comprehensive guides and documentation to start working with WayaQuick."}</p>
                                        
                                        <div className="grid grid-cols-2">
                                            <header className="w-full py-2 border-b border-gray-300">Integration Type</header>
                                            <header className="w-full border-l px-3 py-2 border-b border-gray-300">Integration Type</header>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <header className="w-full py-2 border-b border-gray-300">Server </header>
                                            <header className="w-full border-l px-3 py-2 border-b border-gray-300">
                                                <p className="text-sm">PHP</p>
                                                <p className="text-sm">.NET</p>
                                                <p className="text-sm">JS Checkout</p>
                                                <p className="text-sm">JAVA</p>
                                                <p className="text-sm">NodeJs</p>
                                            </header>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <header className="w-full py-2 border-b border-gray-300">Mobile</header>
                                            <header className="w-full border-l px-3 py-2 border-b border-gray-300">
                                                <p className="text-sm">Android</p>
                                                <p className="text-sm">IOS</p>
                                            </header>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <header className="w-full py-2 border-b border-gray-300">Ecommerce</header>
                                            <header className="w-full border-l px-3 py-2 border-b border-gray-300">
                                                <p className="text-sm">Magento</p>
                                                <p className="text-sm">Woocommerce</p>
                                            </header>
                                        </div>
                                                                        
                                        <p className="paragraph py-2">{"With API, you can also get transaction details, refund your transactions and create payment links programmatically. Primary concepts required for the WayaQuick Integration guide are as follows, which needs to be thoroughly referred to:"}</p>

                                        <p className="paragraph py-2">{"1. Using Test Mode"}</p>
                                        <p className="paragraph py-2">{"2. Generate API Encryption Key (For Test Mode as well as Live Mode)"}</p>
                                        <p className="paragraph py-2">{"3. Integrating WayaQuick (In Website/Application)"}</p>
                                        <p className="paragraph py-2">{"4. Backend payment capturing process"}</p>
                                        <p className="paragraph py-2">{"5. API reference guide"}</p>
                                    </div>
                                )
                            }
                        </div>


                        <div id={contents[1]} onClick={()=> setActiveAccordion("general-payment")} className={`w-ful ${activeAccordion==="general-payment" ? "h-auto": "h-20"} p-6 rounded cursor-pointer border border-gray-300 overflow-hidden my-5`}>
                            <h5 className="flex items-center justify-between gap-1"><span className="flex items-start gap-1"><AccordionIcon/> General Payment Flow with API</span>  {
                                activeAccordion==="general-payment" ? <MinusIcon /> : <PlusIcon/>
                            } </h5>


                            {
                                activeAccordion === "general-payment" && (
                                    <div className="mt-5">
                                        <p id="general-payment" className="subTopic py-2">{"1. Order creation"}</p>
                        
                                        <p className="paragraph py-2">{"After the customer selects the product or service and continues with the checkout page, when he fills up the details then there is one Order ID created also at that time the information is sent to WayaQuick API. Along with that, there is one WayaQuick Order ID created. There is no payment processing during this stage."}</p>

                                        <p id="general-payment" className="subTopic py-2">{"2. Authorization and Capture"}</p>
                        
                                        <p className="paragraph py-2">{"During this stage, an inside process is carried out when the WayaQuick sends the authentication request to the issuing bank for the payment deduction from the customer’s account. After the authorization is received from there, the funds are deducted and are captured by WayaQuick (settlement process can only start after the funds are captured by WayaQuick). After the successful authorization, the merchant’s bank needs to capture the payment. The funds are transferred to the merchant’s bank account after the payment is captured (time period depending on the payout cycle). Once the bank has authorized the customer’s payment, it will be auto captured and it verifies that purchase as complete by the merchant (you) and then no further action is needed from your side. Sometimes, it may happen that the actual real-time status of the transaction is not shown due to network issues, technical errors at customer's/bank's end, etc. In a few hours, it may be reconciled and it shows the real status. This is known as the Late-Authorization."}</p>

                                        <p id="general-payment" className="subTopic py-2">{"3. Refund"}</p>
                        
                                        <p className="paragraph py-2">{"Only once the payment has been captured by you, it can be refunded to the customer. It will be deposited back at the same place from where it was deducted."}</p>


                                        <p id="general-payment" className="subTopic py-2">{"4. Failed"}</p>
                        
                                        <p className="paragraph py-2">{"This is shown when there are some issues in authorizing the payment or if the customer drops out of the transaction due to some reason, or there was a technical error. "}</p>
                                    </div>
                                )
                            }
                        </div>


                        <div id={contents[2]} onClick={()=> setActiveAccordion("authorization")} className={`w-ful ${activeAccordion==="authorization" ? "h-auto": "h-20"} p-6 rounded cursor-pointer border border-gray-300 overflow-hidden my-5`}>
                            <h5 className="flex items-center justify-between gap-1"><span className="flex items-start gap-1"><AccordionIcon/> Authorization</span>  {
                                activeAccordion==="authorization" ? <MinusIcon /> : <PlusIcon/>
                            } </h5>


                            {
                                activeAccordion === "authorization" && (
                                    <div className="mt-5">
                                        <p id="general-payment" className="subTopic py-2">{"1. Web"}</p>
                                        <p className="paragraph py-2">{"For the various technical server-side requests like transaction initialization, transaction details retrieval, refund details, settlement details, etc, you need the unique Access Token obtained from your Merchant Account.‌"}</p>
                                        <p className="paragraph py-2">{"WayaQuick provides unique Access Token and API Secret Key (for web) to every merchant, which is needed to authenticate all of the above mentioned requests.‌"}</p>
                                        

                                        <p id="general-payment" className="subTopic py-2">{"2. Mobile"}</p>
                                        <p className="paragraph py-2">{"For the various technical server-side requests like transaction initialization, transaction details retrieval, refund details, settlement details, etc, you need the unique Access Token obtained from your Merchant Account.‌"}</p>
                                        
                                        <p className="paragraph py-2">{"WayaQuick provides unique Access Token and API Secret Key (for app) to every merchant, which is needed to authenticate all of the above mentioned requests.‌"}</p>
                                    </div>
                                )
                            }
                        </div>


                        <div id={contents[3]} onClick={()=> setActiveAccordion("integration")} className={`w-ful ${activeAccordion==="integration" ? "h-auto": "h-20"} p-6 rounded cursor-pointer border border-gray-300 overflow-hidden my-5`}>
                            <h5 className="flex items-center justify-between gap-1"><span className="flex items-start gap-1"><AccordionIcon/> Types of Integration</span>  {
                                activeAccordion==="integration" ? <MinusIcon /> : <PlusIcon/>
                            } </h5>


                            {
                                activeAccordion === "integration" && (
                                    <div className="mt-5">
                                        <p className="paragraph py-2">{"WayaQuick Checkout offers three different types of Integrations:"}</p>
                                        <p className="paragraph py-2">{"1.  Web-Hosted Checkout"}</p>
                                        <p className="paragraph py-2">{"2. JS Checkout"}</p>
                                        <p className="paragraph py-2">{"3. Seamless Checkout (PCI required)"}</p>

                                        <p id="general-payment" className="subTopic py-2">{"1. Web Hosted Checkout"}</p>
                                        <p className="paragraph py-2">{"It is the simplest and easiest way of checkout. When a customer clicks on the “Pay Now” button on the merchant’s website, they will be redirected to the WayaQuick Payment Gateway Checkout page. Here, customers would choose online payment modes and fill up other payment details to make the payment."}</p>


                                        <p id="general-payment" className="subTopic py-2">{"2. JS Checkout"}</p>
                                        <p className="paragraph py-2">{"In this type of payment mode, clicking on “Pay Now” will display a Pop Up, where the customer needs to fill up all the personal and necessary information and it will be sent to payment gateway server."}</p>

                                        <p id="general-payment" className="subTopic py-2">{"3. Seamless Checkout (PCI Required)"}</p>
                                        <p className="paragraph py-2">{"Seamless Checkout offers true branding, where merchants can create and customize their own Checkout pages and all payment details will be sent to their servers. Hence customer will be able to complete payment without leaving merchant website. (Customer may be redirected to banks authorization page)"}</p>
                                    </div>
                                )
                            }
                        </div>
                                                

                        <div className="grid grid-cols-2 gap-5">
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/payment-link"><ArrowLeft /></Link>
                                <Link to="/payment-link" className="flex flex-col">
                                    <p className="text-sm text-gray-500 text-right"><span className="text-gray-700">Previous</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium text-right">WayaQuick payment Links</h5>
                                </Link>
                            </div>
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/test-environment" className="flex flex-col">
                                    <p className="text-sm text-gray-500"><span className="text-gray-700">Next</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium">Test Environment</h5>
                                </Link>
                                <Link to="/test-environment"><ArrowRight /></Link>
                            </div>
                        </div>
                        
                    </div>
                    <RightNav contents={contents} />
                </div>
            </div>
        </div>
    )
}

export default GetStarted;
