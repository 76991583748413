import React from 'react'

function MobileIntegration() {
  return (
    <div>
      MobileIntegration
    </div>
  )
}

export default MobileIntegration
