

import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {MenuIcon} from '../../assets/MenuIcon';
import Navbar from '../../components/documentation/Navbar.jsx';
import Sidebar from '../../components/documentation/Sidebar.jsx';
import SDKPage from '../../components/SDKPage';
import docContents from '../../components/documentation/content.json';
import {ArrowRight} from '../../assets/index';
import GetStartedImage from "../../assets/docs/get-started.png";
import RightNav from '../../components/documentation/RightNav.jsx';


function DocsGuide() {
    const [activeTab, setActiveTab] = useState('0');
    const [paymentTab, setPaymentTab] =useState("payment-link");

    const contents = ["Registration Guide", "Register", "Verify Email", "Merchant Homepage", "Account Activation"];
    

    const paymentProceedures={
        paymentLink: [
            "Login into your dashboard and navigate to Collect Payments > Payment Links.",
            "Click on Create new payment link.",
            "Select Single charge",
            "Fill in the required details, you can leave the amount section empty to allow your customers to enter the amount to be paid or input an amount so your customers pay a fixed amount as you've set. To add more features to the payment link, click on the customise your payment link option.",
            "We create your payment link and you can now copy and share with your customers!",
            "When your customers click on the link, the Flutterwave payment modal will be displayed for them to supply their payment details.",
            "We charge the provided payment details and credit you."
        ]
    }

    console.log(docContents);

    const setToActive = (e) => {
        setActiveTab(e.target.id);
    }
    return (
        <div>
            <Navbar />
            <div className="w-full flex">
                <Sidebar/>
                <div className="documentation-container px-8 py-4 flex items-start">
                    <div className="content">
                        <h5 id="Registration Guide" className="title w-full font-medium">Registration Guide</h5>
                        <p className="paragraph py-2">{"You need to sign up first to log in to your merchant dashboard and get access to all the functions, test mode, statistics, etc. Here is the prerequisites for Registration and KYC Process:"}</p>
                        <ul className="unorderedList">
                            <li className="py-1 listItem">{"Sign Up - Get Access to Merchant Dashboard and test mode"}</li>
                            <li className="py-1 listItem">{"Submit details and documents - To get them verified and activate services on your account"}</li>
                            <li className="py-1 listItem">{"Get free integration assistance (if required)"}</li>
                            <li className="py-1 listItem">{"Start collecting online payments and get settlements"}</li>
                        </ul>

                        <p className="paragraph py-2">{"Follow the below easy steps to get registered with the WayaQuick Payment Gateway Merchant Account:"}</p>

                        <p id="Register" className="subTopic py-2">{"Step 1: Register"}</p>
                         <ul className="unorderedList">
                            <li className="py-1 listItem">{"Go to the WayaQuick website https://WayaQuick.com"}</li>
                            <li className="py-1 listItem">{"Click on ‘Register’ on the top-right corner"}</li>
                            <li className="py-1 listItem">{"Enter the required basic information"}</li>
                            <li className="py-1 listItem">{"Read and Agree on Terms and Conditions"}</li>
                        </ul>


                        <p id="Verify Email" className="subTopic py-2">{"Step 2: Verify Email"}</p>
                        <p className="paragraph py-2">{"After you click on Create account Button, One Time Password (OTP) would be sent to your email address. Input the OTP code and verify your email."}</p>

                        <p id="Merchant Homepage" className="subTopic py-2">{"Step 3: Merchant Home Page"}</p>
                        <p className="paragraph py-2">{"By default, you are in the test environment. Click SWITCH BUTTON to access the live environment."}</p>

                        <div className="border border-gray-300 rounded p-4">
                            <img src={GetStartedImage} />
                        </div>

                        <p id="Account Activation" className="subTopic py-2">{"Step 4: Account Activation"}</p>
                        <p className="paragraph py-2">{"You must activate your account to access the production environment and accept payment from customers. To activate the account, you must first fill in details about your business and upload the required documents for either tier level 3, 4 and 5. Follow the steps below:"}</p>

                        <p className="paragraph py-2">{"Step 1: Login to the merchant homepage > settings page > KYC verification"}</p>
                        <p className="paragraph py-2">{"Step 2: Complete KYC tier level 1, 2 and 3 or 4 and 5 requirements. These details will be sent for verfication"}</p>
                        <p className="paragraph py-2">{"After successful verification, you can access the production environment for your business transactions."}</p>

                        {/* <p className="paragraph py-2">{p}</p>
                        <p className="paragraph py-2">{p}</p>
                        <p className="paragraph py-2">{p}</p>
                        <p className="subTopic py-2">{""}</p>
                        <p className="subTopic py-2">{"subTitle"}</p>
                        <p className="subTopic py-2">{"subTitle"}</p>
                        <p className="subTopic py-2">{"subTitle"}</p>
                        <ul className="unorderedList">
                            <li className="py-1 listItem">{"li"}</li>
                        </ul>
                        <ul className="unorderedList">
                            <li className="py-1 listItem">{"li"}</li>
                        </ul> */}
                        
                        

                        <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                            <Link to="/payment-life-cycle" className="flex flex-col">
                                <p className="text-sm text-gray-500"><span className="text-gray-700">Next</span> - WayaQuick Overview</p>
                                <h5 className="text-gray-700 text-lg font-medium">Payment Life Cycle</h5>
                            </Link>
                            <Link to="/payment-life-cycle"><ArrowRight /></Link>
                        </div>
                        
                    </div>
                    <RightNav contents={contents} />
                </div>
            </div>
        </div>
    )
}

export default DocsGuide;
