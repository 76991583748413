import React from 'react'

function FraudRuleSection({title, description}) {
    return (
        <div className="px-10 sm:px-24 md:px-48 lg:px-72 xl:px-80 py-8">  
            <svg width="76" height="76" viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="38" cy="38" r="38" fill="#FFEDE6"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M39.94 30.0319C39.3877 30.0319 38.94 29.5842 38.94 29.0319V24H49.2786C50.3832 24 51.2786 24.8954 51.2786 26V39.7454C51.2786 47.0021 45.3959 52.8847 38.1393 52.8847C30.8827 52.8847 25 47.0021 25 39.7454V26C25 24.8954 25.8954 24 27 24H36.1937L32.234 34.6458C31.991 35.2992 32.4742 35.9945 33.1713 35.9945H36.3383C36.8906 35.9945 37.3383 36.4422 37.3383 36.9945V43.8531C37.3383 44.9679 38.887 45.2465 39.2756 44.2017L44.0443 31.3805C44.2874 30.7272 43.8041 30.0319 43.1071 30.0319H39.94Z" fill="#FF6700"/>
            </svg>

            <h5 className="text-gray-900 mt-5 text-lg font-medium">{title}</h5>
            <p className="text-gray-400 mt-2 text-sm">{description}</p>
        </div>
    )
}

export default FraudRuleSection;
