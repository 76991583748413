

import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {MenuIcon} from '../../assets/MenuIcon';
import Navbar from '../../components/documentation/Navbar.jsx';
import Sidebar from '../../components/documentation/Sidebar.jsx';
import SDKPage from '../../components/SDKPage';
import docContents from '../../components/documentation/content.json';
import {ArrowRight, ArrowLeft} from '../../assets/index';
import GetStartedImage from "../../assets/docs/get-started.png";
import Alert from '../../components/Alert/Alert';
import Alert2 from '../../components/Alert/Alert2';
import TE1 from '../../assets/docs/test-env1.png';
import RightNav from '../../components/documentation/RightNav.jsx';

function ApiEnc() {
    const [activeTab, setActiveTab] = useState('0');
    const [paymentTab, setPaymentTab] =useState("payment-link");

    const contents = ["API Encryption Key Guide"];
    

    const paymentProceedures={
        paymentLink: [
            "Login into your dashboard and navigate to Collect Payments > Payment Links.",
            "Click on Create new payment link.",
            "Select Single charge",
            "Fill in the required details, you can leave the amount section empty to allow your customers to enter the amount to be paid or input an amount so your customers pay a fixed amount as you've set. To add more features to the payment link, click on the customise your payment link option.",
            "We create your payment link and you can now copy and share with your customers!",
            "When your customers click on the link, the Flutterwave payment modal will be displayed for them to supply their payment details.",
            "We charge the provided payment details and credit you."
        ]
    }

    console.log(docContents);

    const setToActive = (e) => {
        setActiveTab(e.target.id);
    }
    return (
        <div>
            <Navbar />
            <div className="w-full flex">
                <Sidebar/>
                <div className="documentation-container px-8 py-4 flex items-start">
                    <div className="content">
                        <h5 id={contents[0]} className="title w-full font-medium">API Encryption Key Guide</h5>
                        <p className="paragraph py-2">{"Whether generating Credentials for Test Environment or Live Environment, process is same for both, Live credentials will be generated from live dashboard while test credentials will be generated from test dashboard."}</p>
                        

                        <ul className="unorderedList">
                            <li className="py-1 listItem">{"Log In to your WayaQuick Dashboard"}</li>
                            <li className="py-1 listItem">{"‘Go to’ the Merchant Account."}</li>
                            <li className="py-1 listItem">{"To Generate API Keys, go to the Setting"}</li>
                            <li className="py-1 listItem">{"Then go to the WayaQuick setting."}</li>
                            <li className="py-1 listItem">{"If you are on test mode, you will see the curent  test API keys and to copy the live keys, you will need to toggle to live mode"}</li>
                            <li className="py-1 listItem">{"Click on copy icon to copy your secret and public keys."}</li>
                            <li className="py-1 listItem">{"If you want to change your keys, you can click on genrate new secret keys to regenerate new keys."}</li>
                        </ul>
                        
                        <Alert>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 8V12" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 16H12.01" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div>
                              When using Test Credentials, You need to set: isLive = false in your integration kit.
                            </div>
                        </Alert>
                        <Alert>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 8V12" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M12 16H12.01" stroke="#0086E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <div>
                              If you have generated API Key before then you will see the date of the API Key generated, since you will not be able to retrieve the old API Key (For security reasons) we have provided the re-generate option, so you can re-generate API Key in case you have lost the old one.
                            </div>
                        </Alert> 
                        <Alert2>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.2898 3.8602L1.81978 18.0002C1.64514 18.3026 1.55274 18.6455 1.55177 18.9947C1.55079 19.3439 1.64127 19.6873 1.8142 19.9907C1.98714 20.2941 2.2365 20.547 2.53748 20.7241C2.83847 20.9012 3.18058 20.9964 3.52978 21.0002H20.4698C20.819 20.9964 21.1611 20.9012 21.4621 20.7241C21.7631 20.547 22.0124 20.2941 22.1854 19.9907C22.3583 19.6873 22.4488 19.3439 22.4478 18.9947C22.4468 18.6455 22.3544 18.3026 22.1798 18.0002L13.7098 3.8602C13.5315 3.56631 13.2805 3.32332 12.981 3.15469C12.6814 2.98605 12.3435 2.89746 11.9998 2.89746C11.656 2.89746 11.3181 2.98605 11.0186 3.15469C10.7191 3.32332 10.468 3.56631 10.2898 3.8602V3.8602Z" stroke="#F40000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12 9V13" stroke="#F40000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                              <path d="M12 17H12.01" stroke="#F40000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <div>
                              Once you re-generate API Key your old API Key will stop working immediately. So be cautious while using this option.
                            </div>
                        </Alert2>                   

                        <div className="grid grid-cols-2 gap-5">
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/test-environment"><ArrowLeft /></Link>
                                <Link to="/test-environment" className="flex flex-col">
                                    <p className="text-sm text-gray-500 text-right"><span className="text-gray-700">Previous</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium text-right">Test Environment</h5>
                                </Link>
                            </div>
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/web-integration" className="flex flex-col">
                                    <p className="text-sm text-gray-500"><span className="text-gray-700">Next</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium">Web integration</h5>
                                </Link>
                                <Link to="/web-integration"><ArrowRight /></Link>
                            </div>
                        </div>
                        
                    </div>
                    <RightNav contents={contents} />
                </div>
            </div>
        </div>
    )
}

export default ApiEnc;
