import React from 'react'

function Webhook() {
  return (
    <div>
      Webhook
    </div>
  )
}

export default Webhook
