

import React from 'react';
import { Footer, Navbar } from '../components';
import PricingPage from "../components/PricingPage";

function Pricing() {
    return (
        <div>
            <Navbar />
            <PricingPage/>
            <Footer />
        </div>
    )
}

export default Pricing
