import React from 'react';
import { Navbar, Hero2, Footer, Product } from '../components';
// import Testimonials2 from '../components/Testimonials2';
// import Faq from '../components/Faq';


function Solution() {
    return (
        <React.Fragment>
            <Navbar />
            <Hero2 />
            <Product />
            {/* <Testimonials2 /> */}
            {/* <Faq/> */}
            <Footer />
        </React.Fragment>
    )
}

export default Solution
