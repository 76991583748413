import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import {Navigation} from 'swiper';
import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import Testimonial1 from '../assets/testimony1.png';
import Company from '../assets/mr_biggs.png';


function Testimonials() {
    return (
        <div className="pb-20 pt-10 lg:px-20">
            <div className="testimonial-container flex flex-col items-center py-10">
                <h4 className="title text-3xl font-semibold px-8 text-center md:text-left md:px-0">Our Customer’s Feedbacks</h4>
                <p className="w-full md:w-3/5 text-center font-medium px-8 md:px-14 text-lg text-gray-500 ...">What our customers love about us.</p>
            </div>
            <Swiper
                navigation
                modules={[Navigation]}
                pagination={{ clickable: true }}
                loop
                spaceBetween={10}
                slidesPerView={1}
                onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                <SwiperSlide>
                    <div className="w-full testimonial px-8 md:px-32 grid grid-cols-1 lg:grid-cols-3 pt-10">
                        <div className="">
                            <img src={Testimonial1} alt="Testimonials" />
                        </div>
                        <div className="quote-mark col-span-2 md:pt-16 lg:pt-12 px-4 md:px-16 lg:pl-12 lg:pr-4">
                            <p className="text-justify md:text-xl">I have a very awesome experience with wayapay. I seamlessly use the system and it has boosted my business sales by 64%.</p>
                            <h5 className="font-semibold text-xl mt-6">Rahul Salem</h5>
                            <p>Product Manager 1</p>
                            <img src={Company} alt="" width="75px"/>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="w-full testimonial px-8 md:px-32 grid grid-cols-1 lg:grid-cols-3 pt-10">
                        <div className="">
                            <img src={Testimonial1} alt="Testimonials" />
                        </div>
                        <div className="quote-mark col-span-2 md:pt-16 lg:pt-12 px-4 md:px-16 lg:pl-12 lg:pr-4">
                            <p className="text-justify md:text-xl">I have a very awesome experience with wayapay. I seamlessly use the system and it has boosted my business sales by 64%.</p>
                            <h5 className="font-semibold text-xl mt-6">Rahul Salem</h5>
                            <p>Product Manager 1</p>
                            <img src={Company} alt="" width="75px"/>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="w-full testimonial px-8 md:px-32 grid grid-cols-1 lg:grid-cols-3 pt-10">
                        <div className="">
                            <img src={Testimonial1} alt="Testimonials" />
                        </div>
                        <div className="quote-mark col-span-2 md:pt-16 lg:pt-12 px-4 md:px-16 lg:pl-12 lg:pr-4">
                            <p className="text-justify md:text-xl">I have a very awesome experience with wayapay. I seamlessly use the system and it has boosted my business sales by 64%.</p>
                            <h5 className="font-semibold text-xl mt-6">Rahul Salem</h5>
                            <p>Product Manager 1</p>
                            <img src={Company} alt="" width="75px"/>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="w-full testimonial px-8 md:px-32 grid grid-cols-1 lg:grid-cols-3 pt-10">
                        <div className="">
                            <img src={Testimonial1} alt="Testimonials" />
                        </div>
                        <div className="quote-mark col-span-2 md:pt-16 lg:pt-12 px-4 md:px-16 lg:pl-12 lg:pr-4">
                            <p className="text-justify md:text-xl">I have a very awesome experience with wayapay. I seamlessly use the system and it has boosted my business sales by 64%.</p>
                            <h5 className="font-semibold text-xl mt-6">Rahul Salem</h5>
                            <p>Product Manager 1</p>
                            <img src={Company} alt="" width="75px"/>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    )
}

export default Testimonials
