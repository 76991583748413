import React, {useState} from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../config.json'


function ContactForm() {

    const [fullname, setFullname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");

    const ContactHandler = (e) => {
        e.preventDefault();
        if(fullname && email && phone && subject && description){
            const payloadSender = {
                data: {
                    message: `Your message has been delivered and will be attended to.`,
                    names: [
                        {
                        email: email,
                        fullName: fullname
                        }
                    ]
                },
                eventCategory: "CONTACT",
                eventType: "EMAIL",
                initiator: "1",
                productType: "WAYAPAY"
            };

            // const emailLayout = <div>
            //     <strong>{subject}</strong><br/>
            //     <p>Sender: {email}</p>
            //     <p>Subject: {subject}</p>
            //     <p>Message: {description}</p>
            // </div>

            const payloadWayapay = {
                data: {
                    message: description,
                    names: [
                        {
                        email: 'info@wayaquick.com',
                        fullName: fullname
                        }
                    ]
                },
                eventCategory: "CONTACT",
                eventType: "EMAIL",
                initiator: "1",
                productType: "WAYAPAY"
            };

            const API_EDNPOINT = `${config.REACT_APP_API_URL}/wayapay-notification-api/email-notification-contact` // https://services.wayapay.ng/notification-service/email-notification-contact`;
            axios.post(API_EDNPOINT, payloadWayapay).then(response =>{
                axios.post(API_EDNPOINT, payloadSender).then(response =>{
                    toast("Message sent!");
                    setTimeout(() => {
                        window.location.reload();
                    }, 2500);
                }).catch(err=>{
                    toast("Error occured "+ JSON.stringify(err.response.data));
                })
            }).catch(err=>{
                toast("Error occured "+ JSON.stringify(err.response.data));
            });
        }else{
            toast("Kindly fill all fields!")
        }
    }
    return (
        <React.Fragment>
            <ToastContainer />
            <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="px-5 md:pl-20 pt-20">
                    <h4 className="text-primary-theme text-4xl font-bold">Contact Info</h4>
                    <p className="mt-5">You are welcome to reach us at any of the contacts below</p>

                    {/* <div className="flex gap-4 mt-8">
                        <span>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.3474 6.3301C16.2544 6.24109 16.1305 6.19141 16.0018 6.19141C15.873 6.19141 15.7492 6.24109 15.6562 6.3301L4.1499 17.322C4.10104 17.3687 4.06216 17.4249 4.03563 17.4871C4.00909 17.5493 3.99545 17.6162 3.99553 17.6838L3.99365 28.0001C3.99365 28.5305 4.20437 29.0392 4.57944 29.4143C4.95451 29.7894 5.46322 30.0001 5.99365 30.0001H11.9999C12.2651 30.0001 12.5195 29.8947 12.707 29.7072C12.8945 29.5197 12.9999 29.2653 12.9999 29.0001V20.5001C12.9999 20.3675 13.0526 20.2403 13.1463 20.1465C13.2401 20.0528 13.3673 20.0001 13.4999 20.0001H18.4999C18.6325 20.0001 18.7597 20.0528 18.8535 20.1465C18.9472 20.2403 18.9999 20.3675 18.9999 20.5001V29.0001C18.9999 29.2653 19.1053 29.5197 19.2928 29.7072C19.4803 29.8947 19.7347 30.0001 19.9999 30.0001H26.0037C26.5341 30.0001 27.0428 29.7894 27.4179 29.4143C27.7929 29.0392 28.0037 28.5305 28.0037 28.0001V17.6838C28.0037 17.6162 27.9901 17.5493 27.9636 17.4871C27.937 17.4249 27.8981 17.3687 27.8493 17.322L16.3474 6.3301Z" fill="#FF6700" />
                                <path d="M30.6819 15.2594L26.0069 10.7869V4C26.0069 3.73478 25.9015 3.48043 25.714 3.29289C25.5265 3.10536 25.2721 3 25.0069 3H22.0069C21.7417 3 21.4873 3.10536 21.2998 3.29289C21.1123 3.48043 21.0069 3.73478 21.0069 4V6L17.3869 2.53875C17.0481 2.19625 16.5444 2 16 2C15.4575 2 14.955 2.19625 14.6163 2.53938L1.32252 15.2581C0.933774 15.6331 0.885024 16.25 1.23877 16.6563C1.32761 16.7588 1.43637 16.8422 1.55846 16.9014C1.68055 16.9605 1.8134 16.9942 1.94892 17.0004C2.08445 17.0066 2.21982 16.9852 2.3468 16.9374C2.47378 16.8896 2.5897 16.8165 2.68752 16.7225L15.6563 4.33C15.7493 4.24099 15.8731 4.19131 16.0019 4.19131C16.1307 4.19131 16.2545 4.24099 16.3475 4.33L29.3175 16.7225C29.5086 16.9057 29.7645 17.0057 30.0291 17.0006C30.2938 16.9954 30.5456 16.8855 30.7294 16.695C31.1131 16.2975 31.0813 15.6413 30.6819 15.2594Z" fill="#FF6700" />
                            </svg>
                        </span>
                        <div className="pr-40">
                            <h5 className="text-xl text-gray-600 font-semibold">Head Office</h5>
                            <p className="text-md text-gray-400">69 Seefeldstrasse, Zurich, Switzerland</p>
                        </div>
                    </div> */}

                    <div className="flex gap-4 mt-8">
                        <span>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16.3474 6.3301C16.2544 6.24109 16.1305 6.19141 16.0018 6.19141C15.873 6.19141 15.7492 6.24109 15.6562 6.3301L4.1499 17.322C4.10104 17.3687 4.06216 17.4249 4.03563 17.4871C4.00909 17.5493 3.99545 17.6162 3.99553 17.6838L3.99365 28.0001C3.99365 28.5305 4.20437 29.0392 4.57944 29.4143C4.95451 29.7894 5.46322 30.0001 5.99365 30.0001H11.9999C12.2651 30.0001 12.5195 29.8947 12.707 29.7072C12.8945 29.5197 12.9999 29.2653 12.9999 29.0001V20.5001C12.9999 20.3675 13.0526 20.2403 13.1463 20.1465C13.2401 20.0528 13.3673 20.0001 13.4999 20.0001H18.4999C18.6325 20.0001 18.7597 20.0528 18.8535 20.1465C18.9472 20.2403 18.9999 20.3675 18.9999 20.5001V29.0001C18.9999 29.2653 19.1053 29.5197 19.2928 29.7072C19.4803 29.8947 19.7347 30.0001 19.9999 30.0001H26.0037C26.5341 30.0001 27.0428 29.7894 27.4179 29.4143C27.7929 29.0392 28.0037 28.5305 28.0037 28.0001V17.6838C28.0037 17.6162 27.9901 17.5493 27.9636 17.4871C27.937 17.4249 27.8981 17.3687 27.8493 17.322L16.3474 6.3301Z" fill="#FF6700" />
                                <path d="M30.6819 15.2594L26.0069 10.7869V4C26.0069 3.73478 25.9015 3.48043 25.714 3.29289C25.5265 3.10536 25.2721 3 25.0069 3H22.0069C21.7417 3 21.4873 3.10536 21.2998 3.29289C21.1123 3.48043 21.0069 3.73478 21.0069 4V6L17.3869 2.53875C17.0481 2.19625 16.5444 2 16 2C15.4575 2 14.955 2.19625 14.6163 2.53938L1.32252 15.2581C0.933774 15.6331 0.885024 16.25 1.23877 16.6563C1.32761 16.7588 1.43637 16.8422 1.55846 16.9014C1.68055 16.9605 1.8134 16.9942 1.94892 17.0004C2.08445 17.0066 2.21982 16.9852 2.3468 16.9374C2.47378 16.8896 2.5897 16.8165 2.68752 16.7225L15.6563 4.33C15.7493 4.24099 15.8731 4.19131 16.0019 4.19131C16.1307 4.19131 16.2545 4.24099 16.3475 4.33L29.3175 16.7225C29.5086 16.9057 29.7645 17.0057 30.0291 17.0006C30.2938 16.9954 30.5456 16.8855 30.7294 16.695C31.1131 16.2975 31.0813 15.6413 30.6819 15.2594Z" fill="#FF6700" />
                            </svg>
                        </span>
                        <div className="pr-40">
                            <h5 className="text-xl text-gray-600 font-semibold">Office Address</h5>
                            <p className="text-md text-gray-400">14 Yeye Olofin Street, Lekki Phase 1, Lagos, Nigeria</p>
                        </div>
                    </div>

                    <div className="flex gap-4 mt-6">
                        <span>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_29_19457)">
                                    <path d="M7.41603 3.604L12.021 8.584L8.77003 14.979L15.625 13.75L18.745 21.282L32 3.902L7.41603 3.604ZM6.57303 14.385L7.84903 13.338L6.20203 13.859L6.03003 13.619L6.71303 12.958L5.82203 13.317C2.41503 14.64 -0.000972026 17.937 -0.000972026 21.802C-0.00220885 23.0369 0.249494 24.259 0.738621 25.3929C1.22775 26.5268 1.94393 27.5485 2.84303 28.395C2.06555 27.0319 1.65775 25.4893 1.66003 23.92C1.66003 20.103 4.07703 16.701 7.41503 15.363L7.83803 14.343L6.83803 14.78L6.55703 14.4L6.57303 14.385ZM12.391 11.76L14.522 8L27.053 5.068L12.391 11.76Z" fill="#FF6700" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_29_19457">
                                        <rect width="32" height="32" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        <div className="pr-40">
                            <p className="text-md text-gray-400">info@wayaquick.com</p>
                        </div>
                    </div>

                    <div className="flex gap-4 mt-6">
                        <span>
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_29_19457)">
                                    <path d="M7.41603 3.604L12.021 8.584L8.77003 14.979L15.625 13.75L18.745 21.282L32 3.902L7.41603 3.604ZM6.57303 14.385L7.84903 13.338L6.20203 13.859L6.03003 13.619L6.71303 12.958L5.82203 13.317C2.41503 14.64 -0.000972026 17.937 -0.000972026 21.802C-0.00220885 23.0369 0.249494 24.259 0.738621 25.3929C1.22775 26.5268 1.94393 27.5485 2.84303 28.395C2.06555 27.0319 1.65775 25.4893 1.66003 23.92C1.66003 20.103 4.07703 16.701 7.41503 15.363L7.83803 14.343L6.83803 14.78L6.55703 14.4L6.57303 14.385ZM12.391 11.76L14.522 8L27.053 5.068L12.391 11.76Z" fill="#FF6700" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_29_19457">
                                        <rect width="32" height="32" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </span>
                        <div className="pr-40">
                            <p className="text-md text-gray-400">+234 906 3059 889</p>
                        </div>
                    </div>
                </div>

                <div className="px-5 sm:pl-20 md:pl-0 md:pr-20 pt-20">
                    <h4 className="font-bold text-3xl text-primary-theme">Get in touch</h4>

                    <form method="POST" onSubmit={ContactHandler}>
                        <div className="px-5 sm:pr-20 md:pr-44">
                            <input onChange={(e)=> setFullname(()=>e.target.value)} required="required" type="text" placeholder="Name *" className="h-12 pl-4 my-4 rounded border border-gray-300 w-full text-sm ..." />

                            <input onChange={(e)=> setEmail(()=> e.target.value)} required="required" type="email" placeholder="Email address *" className="h-12 pl-4 my-4 rounded border border-gray-300 w-full text-sm ..." />

                            <input onChange={(e)=> setPhone(()=> e.target.value)} required="required" type="tel" placeholder="Phone number *" className="h-12 pl-4 my-4 rounded border border-gray-300 w-full text-sm ..." />

                            <input onChange={(e)=> setSubject(()=> e.target.value)} required="required" type="text *" placeholder="Subject *" className="h-12 pl-4 my-4 rounded border border-gray-300 w-full text-sm ..." />

                            <textarea onChange={(e)=> setDescription(()=> e.target.value)} required="required" placeholder="Leave us a message" className="h-40 pl-4 my-4 rounded border border-gray-300 w-full text-sm ..."></textarea>

                            <button className="w-2/5 block py-3 bg-primary-theme text-white mb-10 rounded">
                                Send
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ContactForm
