import React, { useEffect } from "react";
import { Link } from 'react-router-dom';


function Terms() {
  let payment = [
    "WayaQuick is an integrated payment and transaction processing company that provides technology integration, advisory products and services, and transaction processing and payment infrastructure to individuals, government and corporate organizations across various sectors and has developed and owns an e-commerce service that authorizes payments for Merchants.",
    "WayaQuick is in collaboration with the acquiring Bank to provide a Payment service gateway to Merchants.",
    "The Merchant is desirous to partner with WayaQuick to use its Payment Gateway to process payment from its customers.",
    "The Parties have agreed to work together in the development and production of certain technical, payment and financing products and have entered into this Agreement for the purpose of documenting their common intention.",
  ];

  let charge = [
    "the transaction amount not being authorized;",
    "the cardholder alleging:",

    "non-participation in the transaction; or",
    "non-authorization of the use of card; or;",
    "non-receipt of goods and/or services purchased;",

    "cancelled or uncompleted pre-authorized transaction; or",
    "suspected fraud on card.",
  ];

  let approved = [
    "A document in the “agreed form” is a reference to a document in a form approved and for the purposes of identification signed by or on behalf of the Parties;",
    "A statutory provision includes a reference to",
    "The statutory provision as modified from time to time (whether before or after the date of this Agreement); and",
    "Any subordinate legislation made under the statutory provision (whether before or after the date of this Agreement);",
    "A person includes a reference to any individual, body corporate, unincorporated association or partnership; and in the case of an individual, to that person’s legal personal representatives, successors or assigns;",
    "A clause or annex is, unless the context otherwise requires, a reference to a clause or annex to this Agreement;",
    "All references to the singular shall include the plural and vice versa;",
    "One gender shall include all other genders;",
    "The words “including” and “in particular” shall be deemed to be followed by the expression “(but not limited to)”;",
    "An account means an account and any sub-accounts of that account and as each may be substituted, renewed, re-designated, replaced or renumbered;",
    "The clause headings in the Agreement have been inserted for convenience only and shall not be taken into account in its interpretation.",
    "Words and expressions defined in any sub-clause, shall for the purposes of the clauses of which the sub- clause forms a part, bear the meaning assigned to such words and expressions in that sub-clause;",
    "If any definition is a substantive provision conferring rights or imposing obligations on any Party, effect shall be given to it as if it were a substantive clause in the body of the Agreement, notwithstanding that it is only contained in the interpretation clause;",
    "If any period is referred to in this Agreement by way of reference to a number of days, the days shall be reckoned exclusively of the first and exclusively of the last day except the last day falls on a Saturday, Sunday or public holiday in which case it shall be made on the previous Business Day;",
    "Any payment which is due to be made under this Agreement which falls on a day which is not a Business Day, shall be made on the first Business Day thereafter, except if it falls in the next month, in which case it shall be made on the previous Business Day;",
    "A person who is not a party to this Agreement has no right to enforce any term of this Agreement; and",
    "The Annexes/Schedules form part of this Agreement and shall have the same force and effect as if set out in the body of this Agreement and references to this Agreement include the Annexes.",
  ];

  let obligations = [
    "work with Acquiring Bank to ensure that settlements of all monies collected by the Merchant is handled in a timely manner in accordance with Applicable Law and Payment Scheme Rules;",
    "grant to the Merchant and the Merchant hereby accepts from WayaQuick limited, non-exclusive, non-transferable license and right to the WayaQuick API and accompanying integration technical specification in respect of this Agreement subject to the Merchant’s acceptance of the WayaQuick Terms and Conditions;",
    "work with the Merchant to provide the requisite integration technical specification and APIs in respect of the Payment Gateway subject to the Merchant’s acceptance of WayaQuick Terms and Conditions;",
    "work with the Merchant to provide fraud protection and compliance support to the Merchant subject to the Merchant’s acceptance of the WayaQuick Terms and Conditions;",
    "set-up the Merchant on WayaQuick Payment Gateway;",
    "develop and provide card & token processing API's for all WayaQuick Payment Scheme and tokens in the market of scope;",
    "provide technology support to the Merchant on a discretionary basis;",
    "provide post-implementation support to the Merchant and its customers;",
    "to maintain an open communication channel with the Merchant to discuss future joint product designs for future initiatives that both Parties can benefit from, and to discuss joint press announcements;",
    "handle settlement of all monies collected by Merchants to Merchant’s bank account in Merchant’s preferred bank;",
    "advise Merchant by email on the start date, upon successful completion of the pre-go live UAT, to carry out a controlled end to end test in the live environment with selected internal users only within Merchant’s organization for a period of one or two weeks in order to certify that the entire project implementation is successful and satisfactory with sign off by Merchant;",
    "provide adequate technical support for the test duration where applicable during the implementation of the task stated in clause 5.11 above.",

    "to offer the WayaQuick infrastructure as method to route supported cards, payment methods & tokens originated transactions through the provided APIs in supported countries as mutually agreed upon;",
    "to work with WayaQuick to implement the 3D-Secure on its site to certify Customer transactions;",
    "to respond to all fraud enquiries not later than one (1) Business Day of receipt of such enquiry,",
    "to respond to all Chargebacks enquiries with sufficient proof and evidence of value/service delivery within one (1) Business Day of receipt of such enquiry;",
    "that for any undisputed Chargebacks, the Merchant will be liable and would have to provide an equivalent sum for Chargebacks;",
    "that for all disputed Chargebacks for which 3D-Secure was used, the Chargebacks shall be subject to arbitration with the Payment Scheme;",
    "to promptly notify WayaQuick of any security breach, misuse, irregularity, suspected fraudulent transaction or suspicious activities that may be connected with attempts to commit fraud or other illegal activity through the use of Merchant’s site and the corrective action the Merchant has taken;",
    "to authorise WayaQuick to debit the nominated bank settlement account for the full value plus other lawful charges in respect of all lawful transaction where the Cardholder is adjudged by the Payment Scheme to be entitled to a refund;",
    "to ensure adequate fraud protection and compliance to regulatory and Payment Scheme rules and requirements;",
    "to notify WayaQuick of any change in the Merchant’s registered office address, Merchant’s activities and/or line of business prior to such change.",
    "to provide a full scope of future plans and use cases of the provided WayaQuick APIs;",
    "to maintain an open communication with WayaQuick to discuss potential future joint product designs for future initiatives that both parties can benefit from, and to discuss joint press announcements;",
    "to put in place appropriate security measures to monitor, control and prevent fraud on Merchant website;",
    "be fully responsible for its employees’ actions while in the Merchant’s employ;",
    "to ensure that at all times, the following information is displayed on its website;",
    "Return, Refund and cancellation Policy",
    "Description of the service (s) being offered for sale",
    "Delivery policy for the service(s) offered for sale",
    "Commitment to process orders promptly (stating in clear terms delivery timelines where applicable)",
    "An undertaking to ensure the security of Cardholders’ information and not to violate the privacy of Cardholders who transact on its site.",
    "Phone number(s) and e-mail address(es) for customer service contact.",
    "An undertaking to respond to all customer enquiries/issues within one (1) Business Day;",
    "to promote the Payment Gateway services to its Customers;",
    "to utilize the integration specification document and APIs in respect of the Payment Gateway provided by WayaQuick in the prescribed manner;",
    "fully comply with all applicable payment scheme rules and government regulations in relations to the transaction entered herein.",
    "ensure that the provided APIs will be used across all of its applicable digital Merchant assets in the prescribed manner;",
    "to carry out an end to end UAT in the live environment with selected internal users only within the Merchant’s organization and not the general public to certify:",
    "to close the project, following successful implementation of the UAT, by signing off a project Go-Live document provided by WayaQuick, for this purpose, before exposing Merchant’s project (service) to the general public;",
    "to provide immediate notice of (i) any unauthorised third-party use of the Services or any third party that may have access to cardholder data; and/or (ii) any event which might lead to such unauthorised use;",
    "to immediately notify WayaQuick of any act, omission or error which does or may adversely affect the Merchant's ability to perform their obligations under this Agreement or cause loss or damage to WayaQuick (including but not limited to any material change in the nature or extent of the Merchant's business).",
    "The Merchant shall comply with any additional security, authentication, risk control or other requirements imposed by WayaQuick or a Payment Scheme, including but not limited to where that Merchant is, in the opinion of WayaQuick and / or the Payment Scheme, engaged in high risk activities.",
    "to comply with Applicable law and any relevant Payment Scheme Rules to which the Merchant is subject. The Merchant shall not act in contravention of or cause WayaQuick to act in contravention of any Payment Scheme Rules to which WayaQuick is subject.",
    "Maintain a 10% rolling reserve from daily settlement due to the Merchant for a period of 180 days as provided in Clause 13 below.",
  ];
  let refunds = [
    "If applicable, the Merchant acknowledges and agrees that in certain circumstances the Issuing Bank, Payment Scheme or other financial institutions may (i) refuse to settle a transaction or (ii) impose Chargebacks on WayaQuick.",
    "The Merchant agrees that it may be required to reimburse WayaQuick for Chargebacks where the Merchant has accepted settlement in respect of the relevant transaction. Where applicable, the Merchant must respond to Cardholder disputes and handle Chargebacks in accordance with Payment Scheme Rules.",
    "The Merchant agrees that WayaQuick shall have the right to send non-settled transactions received from the Merchant Customers to the Issuing Bank/authorities concerned for the purpose of checking and in case of objection and/or opposition on the executed transactions by the Issuing Bank or the authorities concerned for any reason, WayaQuick shall not be bound to pay the Merchant the transaction amount during the period contained in this agreement.",
    "All Chargebacks shall correspond to the whole or part of the settlement value of the original transaction.",
    "Where Chargebacks occur, WayaQuick shall immediately be entitled to debit the Merchant’s position or make a reversal from the Merchant’s bank account and/or make a deduction from any remittance and /or invoice the Merchant to recover:",
    "childItem",
    "childItem",
    "A Chargeback represents an immediate liability from the Merchant to WayaQuick and where the full amount of any Chargebacks and/or any Chargeback Costs is not debited by WayaQuick from the Merchant bank account or deducted from any remittance or invoiced as referred to in the previous clause, then WayaQuick shall be entitled to otherwise recover from the Merchant by any means, the full amount of such Chargeback or Chargeback Costs (or the balance thereof, as the case may be).",
    "WayaQuick shall not be obliged to investigate the validity of any Chargeback by any Issuing Bank, Payment Scheme or other financial institution, whose decision shall be final and binding in respect of any Chargebacks.",
    "As Chargebacks may arise a considerable period after the date of the relevant transaction, WayaQuick shall remain entitled to recover Chargebacks and Chargeback Costs from the Merchant in respect of all Chargebacks, even after the termination of the contractual relationship between the Merchant and WayaQuick.",
    "WayaQuick may immediately terminate this Agreement and the Services provided hereunder if WayaQuick in its sole opinion considers that the total value of Refunds and/or Chargebacks is unreasonable.",
    "The Merchant agrees and confirms that it shall remain solely liable after the termination of the Agreement for all Chargebacks, Refunds, penalties, loss, damages or cost incurred by WayaQuick, Acquiring Bank, and/or Customers and for all claims and proceedings arising against WayaQuick with respect to the Agreement.",
  ];
  let off = [
    "WayaQuick may, without notice, set off any debts or liabilities due from the Merchant to WayaQuick under this Agreement against any debts or liabilities owed by WayaQuick to the Merchant, regardless of the place of payment or currency of either obligation. If the obligations are in different currencies, WayaQuick may convert either obligation at a market rate of exchange in its usual course of business for the purpose of the set-off.",
    "WayaQuick is entitled to defer any settlement or any other sum due to the Merchant to the extent that WayaQuick considers necessary or appropriate to protect their ability to recover the Fees and/or the sums or any other liability (actual or anticipated) of the Merchant in connection with this Agreement.",
    "If WayaQuick has reasonable suspicion that a transaction may be fraudulent or involve other criminal activity, WayaQuick may suspend the processing of that transaction and any connected, transaction, or withhold settlement until the satisfactory completion of any investigation. The Merchant shall not be entitled to any interest or other compensation whatsoever in respect of suspension or delay in receiving Payment.",
    "The exercise by WayaQuick of any of its rights under this clause shall be without prejudice to any other rights or remedies (including but not limited to set-off) to which WayaQuick is otherwise entitled (by operation of law, contract, or otherwise).",
  ];
  let fee = [
    "under this Agreement is structured in Annexure 1 and as may be updated from time to time on Wayapay.ng/pricing",
    "direct processors fees from Payment Scheme which are already contained in the fees contained in clause 9.1 above.",
    "reduce/waive its transaction fees provided in Annexure 1 from time to time without recourse to the Merchant but shall notify the Merchant of such increase/reduction/waiver immediately upon its implementation.",
    "WayaQuick is entitled to recover and withhold:",
    "any Refunds; and",
    "any Refunds; and",
    "any Chargebacks and any Fines Visa, MasterCard, American Express, Discover® Global Network and any Affiliates thereof or any other card payment network.",
    "The Merchant may markup fees to its Customers without recourse to WayaQuick.",
    "The Merchant hereby gives WayaQuick full permission and authorization to receive all settlements and collections on its behalf, from the Acquiring Bank, and to liaise with the Acquiring Bank, in order to make all due settlements to it, and on its behalf, through the WayaQuick platform.",
  ];
  let Warranty = [
    "The Merchant warrants that it has never had an agreement with a payment scheme provider which was terminated upon request and/or demand by the payment scheme provider or any regulatory authority.",
    "The Merchant warrants that it shall not submit any transaction that the Merchant knows is illegal, fraudulent or restricted for authorization, or not authorized by the cardholder.",
    "The Merchant warrants it will conduct appropriate due diligence on all Customers.",
    "The Merchant warrants that it shall fully cooperate where any forensic investigation is being conducted on the Merchant until such time the investigation is completed.",
    "The Merchant warrants it will use the Services in good faith, in accordance with the terms of this Agreement and in accordance with all Applicable Law and Payment Scheme Rules. In particular, the Merchant will not use the Services in a manner that that could result in a violation of anti-money laundering, counter terrorist financing and similar legal and regulatory obligations.",
    "The Merchant warrants that the Merchant has not been subject to the following:",
    "Criminal conviction (except minor traffic offenses and other petty offenses) in Nigeria or in any other foreign country;",
    "Federal or state tax lien, or any foreign tax lien;",
    "Administrative or enforcement proceedings commenced by the Securities and Exchange Commission, any Regulatory Authority, in Nigeria, or in any other country; or",
    "Restraining order, decree, injunction, or judgment in any proceeding or lawsuit, alleging fraud or deceptive practice on the part of the Merchant.",
    "The Parties warrants that they are duly registered and licensed (where applicable), and have the full capacity, regulatory approvals and corporate authorisation to enter into this Agreement and discharge the obligations and responsibilities created herein.",
    "The Parties further warrant that no element of this transaction constitutes a breach of any existing law, regulation, patent, copyright, or other intellectual property in its country or countries of domicile and operation.",
    "The Parties warrant that in the case of any third-party software used in respect of this Agreement, that they have the required licence and the right to grant a sub-licence to use such third-party software.",
    "Each Party warrants to the other that this Agreement constitutes a legal, valid and binding obligation, enforceable against it in accordance with the terms and obligations therein and no provision of this Agreement is in conflict with any of the Party’s obligations under its constitutional documents, Applicable Law or any other document, charter or agreement to which the Party is subject,",
    "The Parties shall keep each other indemnified against all actions, claims, proceedings and all legal cost or other expenses arising out of any breach of the above warranties or out of any claim by a third party based on any facts which if substantiated would constitute such a breach or a breach of other relevant legal or contractual duty.",
    "WayaQuick neither warrants that the use of the Payment Gateway or the operation thereof will be uninterrupted nor error free, however, WayaQuick warrants that it shall use its best endeavours to ensure that the Payment Gateway functions optimally at all times and within generally accepted industry standards during the term of this Agreement.",
    "Except as set forth in this clause 10, WayaQuick makes no express or implied representations or warranties with respect to the Payment Gateway and related services or their condition, merchantability, fitness for any particular purpose or use by the Merchant or the Merchant’s customers.",
    "Disclaimer. EXCEPT FOR THE WARRANTIES SPECIFICALLY SET FORTH IN THIS AGREEMENT, EACH PARTY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, ARISING OUT OF OR RELATED TO THIS AGREEMENT, INCLUDING WITHOUT LIMITATION, ANY WARRANTY OF MARKETABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON- INFRINGEMENT, AND IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE, EACH OF WHICH IS HEREBY EXCLUDED BY AGREEMENT OF THE PARTIES.",
  ];
  let indem = [
    "With respect to any negligent act or omission by, or wilful misconduct of, the Party’s employees or agents to the extent however that such negligent act can be situated in the normal course of employment or appointment;",
    "The violation of any applicable law, statute or regulation by the Parties or their personnel;",
    "In connection with any third party claims, actions, demands and/or losses to the extent that such losses result from any breach of this Agreement by such indemnifying Party.",
    "The Merchant shall indemnify and hold WayaQuick harmless from and against any damage, loss or liability that WayaQuick may incur as a result of:",
    "Merchant wilfully or carelessly exposing the process/product/service to the general public during the process of carrying out tests in the controlled Live environment as stated in clauses 6.1.18 and 6.1.19 above.",
    "Negligent or wilful misconduct of the Merchant and/or its employees, Affiliates, employees and agents.",
    "Any modification or amendment of the prescribed terms of use communicated by WayaQuick, that WayaQuick did not specifically approve in writing.",
    "Any warranty, condition, representation, indemnity or guarantee granted by the Merchant with respect to the Payment Gateway with respect to the limited warranties specified in clause 10;",
    "Any omission or inaccuracy in the Merchant’s advertisements or promotional materials that relate to the Payment Gateway,",
    "Any modification of or addition to the Payment Gateway not provided or approved by WayaQuick or;",
    "The Merchant’s failure to comply with the terms and conditions of this Agreement or any part thereof.",
    "This section will not be construed to limit or exclude any other claims or remedies to which WayaQuick may be entitled hereunder or in law or equity.",
  ];
  let fraud = [
    "Fraudulent transactions shall include but not be limited to:",
    "Any purchase of goods and services and/or transaction arising from the use of a card by a person other than the authorized cardholder.",
    "Use of a card that is not authorised in terms of the rules governing the issuance and use of cards.",
    "In the event that fraudulent transactions account for more than 0.10% of the Merchant’s sales turnover in any one (1) month, WayaQuick may, in addition to any other remedy available to it, be entitled to unilaterally terminate this Agreement forthwith and/or request the Merchant to delist the affected customer from the use of the platform and terminate the Merchant Agreement with the affected Customer.",
    "Merchant agrees that it will always be responsible for the actions of;",
    "the Merchant’s customers; and",
    "The Merchant’s employees",
    "including fraudulent acts or omission not traceable to the contributory negligence of WayaQuick.",
  ];
  let limit = [
    "The liability of WayaQuick to the Merchant, whether in contract, negligence, and other tort, by way of indemnity or otherwise arising out of or in connection with this Agreement shall be subject to the financial limits set out below:",
    "In no event shall WayaQuick be liable to the Merchant in excess of any amount that has accrued to WayaQuick from transactions emanating by virtue of this Agreement, in the month immediately preceding the date the first such claim arises.",
    "No liability shall be raised against WayaQuick more than two (2) years after the accrual of the cause of such liability, therefore. It is further agreed that the limitations on liability, expressed herein, shall inure to the benefit of and apply to all parents (both direct and indirect), subsidiaries and Affiliates of WayaQuick.",
    "WayaQuick will not be liable for the actions or inactions of any third party not acting on the instructions of WayaQuick; neither will WayaQuick be liable for the actions or inactions not directly traceable to it.",
  ];
  let Term = [
    "Either Party may terminate this Agreement forthwith by giving’ notice in writing to the other Party if:",
    "the other Party takes any step or action in connection with its entering administration, provisional liquidation or any composition or arrangement with its creditors (other than in relation to a solvent restructuring), being wound up (whether voluntarily or by order of the court, unless for the purpose of a solvent restructuring), having a receiver appointed to any of its assets or ceasing to carry on business or, if the step or action is taken in another jurisdiction, in connection with any analogous procedure in the relevant jurisdiction; or",
    "the other Party suspends, or threatens to suspend, or ceases or threatens to cease to carry on all or a substantial part of its business.",
    "This Agreement may also be terminated forthwith by any of the Parties on giving written notice to the other, if the other Party is in material breach of the terms of this Agreement and has failed to rectify such breach (in the case of a breach capable of being remedied) within ten (10) Business Days of receiving a written notice requiring it to do so.",
    "WayaQuick may terminate this Agreement and delist the Merchant where the Merchant is identified as a source of fraudulent activity or causes damage to WayaQuick’s brand or the Acquiring Bank and Payment Schemes or upon instruction from the Acquiring Bank and Payment Schemes whether financial or otherwise.",
    "Either Party may terminate this Agreement at any time on giving one (1) month’s written notice to the other Party.",
    "On Termination, the Parties shall be discharged from any liability for further performance of its obligations under this Agreement and shall entitle either Party to be paid the accrued sum for any successful transaction prior to such termination.",
    "Any termination of this Agreement (however occasioned) shall not affect any accrued rights or liabilities of any of the Parties nor shall it affect the coming into force or the continuance in force of any provision hereof which is expressly or by implication intended to come into or continue in force on or after such termination.",
    "Upon termination of this Agreement the Merchant’s right to use the Payment Gateway shall automatically be revoked and WayaQuick shall retain the merchant records for a period of five (5) years after such termination.",
  ];
  let con = [
    "In order to implement this Agreement, Both Parties may receive and have access to certain information belonging to the other Party which the other Party may designate as Confidential Information, provided, the Parties recognize that they",
    "will not have access to, or share, non-public personal information regarding consumers under this Agreement. Both Parties agree that all Confidential Information is and shall remain the property of the Party providing the information and the Party receiving or gaining access to the information shall use all reasonable and prudent means to safeguard such Confidential Information, including all means required by law. Furthermore, neither Party shall copy, publish, disclose to others, or use such Confidential Information for any purpose other than the fulfilment of its obligations under this Agreement or where required by law.",
    "Each of the Parties on behalf of itself and its employees, officers, directors, Affiliates, and agents, hereby agrees that Confidential Information made available to it will not be disclosed or made available to any third party, agent or employee for any reason whatsoever, other than with respect to: (i) its employees on a “need to know” basis, (ii) Affiliates on a “need to know” basis, provided that they are subject to a confidentiality agreement which shall be no less restrictive than the provisions of this Section 17; and (iii) as required by Applicable Law or as otherwise permitted by this Agreement, either during the term of this Agreement or after the termination of this Agreement, provided that prior to any disclosure of any party’s Confidential Information as required by law, the party subject to the requirement shall (iv) notify the other parties of all, if any, actual or threatened legal compulsion of disclosure, and any actual legal obligation of disclosure immediately upon becoming so obligated and (v) cooperate with the other parties’ reasonable, lawful efforts to resist, limit, or delay disclosure.",
    "Upon Termination of this Agreement, or at any time upon the request of the other party, each party shall return all Confidential Information in the possession of such Party or in the possession of a third party (over which such party has or may exercise control).",
    "In the event of any breach of the obligations under this Section 17, each Party acknowledges that the other Party may have no adequate remedy at law, in addition to such other remedies as may be available to the other Party, the other Party may obtain injunctive relief.",
    "This Section shall survive any termination or expiration of this Agreement.",
  ];
  let force = [
    "If any of the Parties hereto is prevented from fulfilling its obligations under this Agreement by reason of any supervening event beyond its control (including but not limited to an Act of God, Natural Disaster, or Civil Disorder) the Party unable to fulfil its obligations shall immediately give notice in writing of this to the other Party and shall do everything in its power, including but not limited to accepting assistance from third parties or the other Party, to resume full performance.",
    "If the period of incapacity exceeds two (2) months, then this Agreement shall automatically terminate unless Parties expressly agree otherwise in writing",
  ];
  let party = [
    "The relationship between the Parties hereto shall be one of collaboration for the single purpose of the business relationship herein created.",
    "This Agreement shall not in any way constitute a partnership or joint venture between the Parties or constitute either Party an agent of the other.",
    "This Agreement is not intended to confer on any person other than WayaQuick and the Merchant, any express or implied benefit or burden.",
  ];
  let proper = [
    "Nothing set forth in this Agreement shall constitute a transfer or assignment by one Party to another Party of any Intellectual Property Rights owned or otherwise controlled by such Party, and each Party hereby retains all of its rights, title and interest in such Intellectual Property Rights.",
    "All Intellectual Property Rights in or related to the Payment gateway are and will remain the exclusive property of WayaQuick, whether or not specifically recognized or perfected under the laws of the jurisdiction in which the Payment gateway is used or licensed. The Merchant shall not take any action that jeopardizes WayaQuick's proprietary rights or acquire any right in the Payment gateway, or the Confidential Information, as defined herein. Unless otherwise agreed on a case-by-case basis, WayaQuick will own all rights in any copy, translation, modification, adaptation or derivation of the Payment gateway or other items of Confidential Information, including any improvement or development thereof.",
  ];
  let No = [
    "The, rights benefits or obligations under this Agreement may not be assigned or otherwise transferred in whole or in part without the prior written consent of all the Parties but shall be binding upon and inure to the benefit of each of the Parties and, where so permitted, their assigns or other transferees.",
    "WayaQuick may sub-contract or delegate the performance of its obligations under this Agreement to third parties including any of its Affiliates; however, the Merchant shall remain responsible for the performance of its duties under this Agreement.",
  ];
  let Dispute = [
    "WayaQuick shall not be involved in any disputes that may arise between the Merchant and any of its Customers, unless such dispute specifically relates to transaction settlement.",
    "In the event of a dispute between Parties with respect to any issue arising out of or relating to this Agreement in any manner, including but not limited to the breach thereof, resolution of which cannot be resolved amicably by the Parties through negotiation within thirty (30) days shall be resolved by arbitration at the Lagos Court of Arbitration (LCA) before a single arbitrator in accordance with the Arbitration and Conciliation Act, Cap A18, Laws of the Federation of Nigeria.",
    "The Parties shall be entitled to require the appointment of an arbitrator by the Chairperson of Chartered Institute of Arbitrators (UK), Nigeria Branch, where the Parties fail to reach an amicable decision of the arbitrator.",
    "The arbitration shall be held in Lagos, Nigeria and conducted in English language.",
    "The decision of the arbitrator shall be final and binding on the Parties and Parties agree to be bound by it. Such decision shall be rendered within thirty (30) days of the closing of the hearing record.",
    "The cost of arbitration shall be borne equally by the Parties.",
    "The Arbitral award rendered by the arbitrator shall be entered in any court of competent jurisdiction as judgment. No award of punitive damages by the arbitrator may be enforced.",
  ];
  let note = [
    "Any notice required or permitted by this Agreement to be given to either Party by the other shall be given by personal delivery to such Party or by registered or certified mail, electronic mail, postage prepaid, return receipt requested, and addressed to:",
  ];
  let anti = [
    "Each Party hereby undertakes that, at the date of this Agreement, itself, its directors, officers, employees or Affiliates have not offered, promised, given, authorized, solicited or accepted any undue pecuniary or other advantage of any kind (or implied that they will or might do any such thing at any time in the future) in any way connected with this Agreement and that it has taken reasonable measures to prevent subcontractors, agents or any other third parties, subject to its control or determining influence, from doing so.",
    "Each Party shall comply with all applicable anti-bribery and anti-corruption Laws in Nigeria (including the Economic and Financial Crimes commission laws and the Foreign Corrupt Practice Act of the United States of America and the Bribery Act 2010 of the United Kingdom) and all applicable anti-bribery and anti-corruption regulations and codes of practice.",
    "The Merchant shall ensure that this clause 30 is observed in all relationship with its customers.",
  ];
  return (
    <div className="px-8 lg:px-40 pb-20">
      <h4 className="title text-3xl font-semibold px-8 text-center md:px-0 my-16">
        SERVICE LEVEL AGREEMENT WAYAQUICK
      </h4>
      <div>
        <h2 className="font-semibold"> Introduction</h2>
        <h5>
          PLEASE READ THE FOLLOWING AGREEMENT, ALONG WITH ITS TERMS AND
          CONDITIONS, THE WAYAQUICK TERMS OF USE AND PRIVACY POLICY CAREFULLY
          BEFORE ACCESSING OR USING THE WAYAQUICK SERVICES. NOTE THAT THESE
          TERMS AND CONDITIONS CONSTITUTE A LEGAL AGREEMENT BETWEEN YOU (THE
          MERCHANT) AND WAYAQUICK (THE SERVICE PROVIDER). YOU MAY NOT ACCESS OR
          USE ANY WayaQuick SERVICES UNLESS YOU AGREE TO ABIDE BY ALL OF THE
          TERMS AND CONDITIONS AS CONTAINED IN THIS AGREEMENT.
        </h5>
        <h1 className="font-bold mt-3">
          Please read the contents of this document carefully and print the copy
          sent to your email and/or retain this information electronically for
          your records
        </h1>
        This Merchant Service Agreement (“Agreement”) is a legal agreement
        between WayaQuick (together with its subsidiaries, affiliates,
        successors and assigns) And You (hereinafter referred to as the
        Merchant), as a user in order for You to receive certain payment gateway
        services and other services offered by WayaQuick and or its subsidiaries
        and affiliates. WayaQuick and Merchant are herein referred to
        collectively as “ Parties” and individually as “ Party”, whereas
        <ol>
          {payment.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
        Now It Is Hereby Agreed As follows:
      </div>
      <div className="mt-5">
        <h2 className="font-bold">Definitions</h2>
        In this Agreement, the following definitions apply: “3D-Secure” means
        the backup two (2) levels of security which must include delivery of a
        dynamic code in a card transaction “Acquiring Bank” means the financial
        institution/bank institution that processes credit or debit card
        payments on behalf of a merchant. “Affiliate“ means, in relation to any
        Party, any entity in the same group as that Party, including but not
        limited to a subsidiary or a holding company of that Party and any
        direct or indirect subsidiaries of such holding company; “Agreement”
        means this Merchant Service Agreement and any supplements, appendices,
        amendments, modifications, extensions and revisions therein; “API” means
        Application Program Interface belonging to WayaQuick for the purpose of
        providing the Services “Applicable Law(s)” includes but is not limited
        to all Nigerian legislation, law, regulation, code, guidelines, rules,
        policies and directives of any competent regulatory entity, including
        without limitation CBN and NIBSS requirements with respect to payments,
        data protection, data privacy and data security, or any successor
        legislation, law, regulation, code, guidelines, rules, policies and
        directives amending, consolidating or replacing such Applicable Law,
        that is applicable to the deployment or operation of the core IT Banking
        Solution as well as any Services to be provided with respect to this
        Agreement, and any court decision having the force of law in Nigeria;
        “Business Day” means any day other than Saturday, Sunday, or any other
        day on which banking institutions in the Territory are authorized by law
        or executive action to close; “Card” means a prepaid virtual and/or
        physical card issued by Issuing Bank, which is branded with one or more
        marks or signage of a Payment Scheme, offered by WayaQuick to its
        Merchants on the WayaQuick Platform “Cardholder” means any authorised
        user of a card who uses the card to carry out a card transaction on the
        Payment Gateway “Confidential Information” means all information
        relating to the Disclosing Party which is obtained, whether in writing,
        pictorially, in machine readable form or orally or by observation in
        connection with this Agreement, including but without limitation,
        financial information, know-how, processes, ideas, intellectual property
        (irrespective of its registrability or patentability status),
        schematics, trade secrets, technology, customer list (potential or
        actual) and other customer-related information, sales statistics,
        market, market intelligence, marketing and other business strategies and
        other commercial information of a confidential nature but does not
        include information which is known to the Receiving Party without any
        limitation or restriction on use or disclosure before receipt of such
        information from or on behalf of the disclosing party or becomes
        publicly available, other than as a breach of this Agreement, or becomes
        lawfully available to the Receiving Party from a third party free from
        any confidentiality restriction or any information required to be
        disclosed under any relevant law or any binding judgment or order of
        court or arbitration tribunal or any stock exchange regulations or under
        direction from any relevant regulatory authority; “Customers” means
        patrons of the Merchant in relation to a payment transaction processed
        using the Services; “Chargebacks” means the reversal of a transaction or
        request for repayment in respect of a transaction previously settled
        and/or remitted that comes from the Issuing Bank, Payment Scheme or
        other financial institution. The reasons for the Chargebacks include
        (but are not limited to):
        <ul>
          {charge.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
        <ol>
          {charge.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
        “Disclosing Party” means any one of the Parties under this Agreement who
        discloses Confidential Information to the other Party to this Agreement;
        “WayaQuick Terms and Conditions” means collectively the terms and
        conditions set forth in this Agreement, as may be amended from time to
        time, including all exhibits, schedules and addendum thereto and the
        terms and conditions of the Payment Gateway on
        <Link to="/terms">WayaQuick.com/terms</Link> “Fines” means any and all fines, levies,
        costs, expenses, charges, assessments or imposition of liabilities of
        any nature which the Payment Schemes or other financial institution
        require either the Merchant or WayaQuick to pay or which are otherwise
        directly or indirectly recovered from WayaQuick at any time and which
        relate to any aspect of this Agreement (including the provision of the
        Services hereunder). “Intellectual Property Rights” means patents,
        rights to inventions, copyright and related rights, trademarks, trade
        names and domain names, rights in get-up, goodwill and the right to sue
        for passing off, unfair competition rights, rights in designs, rights in
        computer software, database rights, topography rights, rights to
        preserve the confidentiality of information (including know-how and
        trade secrets) and any other intellectual property rights, in each case
        whether registered or unregistered and including all applications (or
        rights to apply) for and be granted, renewals or extensions of, and
        rights to claim priority from, such rights and all similar or equivalent
        rights or forms of protection which subsist or will subsist now or in
        the future in any part of the world; “Issuing Bank” means a financial
        institution that issues cards under the authority of the relevant
        Payment Scheme; “Merchant” means an end user customer that uses
        WayaQuick Services in the conduct of its business of selling goods or
        providing services to the Public; “Payment Gateway” means the
        infrastructure and e-commerce service of WayaQuick that authorizes
        payments for merchants; “Payment Scheme” means Visa, MasterCard,
        American Express, Discover® Global Network and any Affiliates thereof or
        any other card payment network (including any local schemes thereof)
        and/or such other schemes governing the issue and use of credit, debit,
        charge, purchase or any other cards or payment methods, as approved and
        notified by WayaQuick to the Merchant in writing or on the WayaQuick
        websites from time to time; “Payment Scheme Rules” means individually
        and collectively, any and all applicable rules, regulations, standards
        and operating guidelines issued by any Payment Scheme, as amended and
        restated from time to time; “PCI-DSS” means the Payment Card Industry
        Data Security Standards; “Receiving Party” means any person receiving
        Confidential Information from a Party under this Agreement; “Refund”
        means a return of an amount to a Customer or the reversal of any other
        payment pursuant to a request or instruction from the Merchant to
        WayaQuick; “Regulatory Authority” means any regulator or other public
        body having supervisory or regulatory authority over WayaQuick or the
        Merchant; “Services” means usage of the WayaQuick APIs by the Merchant
        for its payment services; “Territory” means the Federal Republic of
        Nigeria; and “User Acceptance Test” (UAT) means the last phase of
        testing of the Payment Gateway integration to ensure functionality
        according to specification.
      </div>

      <div className="mt-5">
        <h2 className="font-bold"> In this Agreement, a reference to:</h2>
        <ul>
          {approved.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </div>
      <div className="mt-5">
        <h1 className="font-bold">2. Non-exclusivity</h1>
        WayaQuick hereby grants the Merchant the non-exclusive right to channel
        its customers through the Payment Gateway.
      </div>
      <>
        <h1 className="font-bold">3. Non-exclusivity</h1>
        Each Party is responsible for ensuring data security on their
        platform/website and for all data and Confidential information acquired
        pursuant to this Agreement. Each Party shall be and remain compliant
        with the Payment Card Industry Data Security Standard (PCI/DSS)
        requirement to the extent applicable to that Party and prior to such
        Party accessing any payment cardholder data or credit card information,
        as such requirements may be amended from time to time.
      </>
      <>
        <h1 className="font-bold mt-5">4. Term</h1>
        This Agreement shall commence from the date of the last signature
        (“Effective Date”) and shall continue for a period of twelve (12) months
        (“Initial Term”) unless any Party terminates the Agreement in accordance
        with this Agreement. Upon expiry of the initial term, this Agreement
        shall automatically renew for successive one (1) year periods until
        terminated in accordance with this Agreement.
      </>
      <>
        <h1 className="font-bold mt-5"> 5. WayaQuick’s Obligations </h1>
        WayaQuick hereby agrees to:
        <ol>
          {obligations.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>

      <>
        <h1 className="font-bold mt-5"> 6. The Merchant’s Obligations </h1>
        6.1. The Merchant hereby agrees:
        <ol>
          {obligations.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
        <p>
          6.2. In order to ensure continued compliance with the requirements of
          the CBN, the Merchant understands and accepts that WayaQuick provides
          its Services subject to the written approvals, directives, notices or
          authorisations as may be issued by the CBN from time to time and the
          Payment Scheme Rules. The Merchant further accepts that WayaQuick may
          make any such changes to the Services or this Agreement as are
          strictly necessary to ensure compliance with the CBN and the Payment
          Scheme Rules and the continuous provision of the Services to the
          Merchant. 6.3. The Merchant acknowledges that the Cards created on the
          WayaQuick Platform are the property of WayaQuick, and will be subject
          to cancellation at any time by WayaQuick or the Issuing Bank, as
          required by Applicable Law, or, on a case-by-case basis, where
          WayaQuick and/or the Issuing Bank believes that the Card is being used
          for fraudulent or illegal purposes provided that WayaQuick shall
          immediately notify the Merchant of such cancellation. 6.4. The
          Merchant agrees that it will be responsible for and liable to
          WayaQuick and Issuing Bank for all reasonable expenses associated with
          and any losses from over limit processing or reasonable expenses
          incurred by WayaQuick or Issuing Bank in seeking fraud or unauthorized
          transaction recovery under Applicable Law that was a result of the
          Merchant’s action or inaction including actions of the Merchant’s
          employees.
        </p>
      </>
      <>
        <h1 className="font-bold mt-5"> 7. Chargebacks And Refunds </h1>
        <ol>
          {refunds.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 8. Set Off </h1>
        <ol>
          {off.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 9. Fees & Revenue Share </h1>
        <ol>
          {fee.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 10. Warranties </h1>
        <ol>
          {Warranty.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 11. Indeminty </h1>
        Either Party shall indemnify and hold the other Party, its Affiliates,
        employees and agents harmless from and against any damage, loss,
        expense, claims or liability that Party may incur:
        <ol>
          {indem.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 12. Fraudulent Transaction </h1>
        <ol>
          {fraud.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 13. Limitation of Liability </h1>
        <ol>
          {limit.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 14. Termination </h1>
        <ol>
          {Term.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 15. Confidentiality </h1>
        <ol>
          {con.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 16. Force Majaure </h1>
        <ol>
          {force.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 17. Relationship between Parties </h1>
        <ol>
          {party.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 18.Intellectual Properties </h1>
        <ol>
          {proper.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 19. No Assignment </h1>
        <ol>
          {No.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 20. Modifications </h1>
        This Agreement may not be modified except by an instrument in writing
        signed by duly authorized representatives of each of the Parties.
      </>
      <>
        <h1 className="font-bold mt-5"> 21.Waiver </h1>
        The respective rights of the Parties (whether arising under this
        Agreement or under the applicable law) shall not be capable of being
        waived or varied otherwise than by an express waiver or variation in
        writing; and in particular any failure to exercise or any delay in
        exercising any of such rights shall not operate as a waiver or variation
        of that or any other such right; any defective or partial exercise of
        any of such right shall not preclude any other or further exercise of
        that or any other such right; and no act or course of conduct or
        negotiation on the part of either Party shall preclude them from
        exercising any such right or constitute a suspension or variation of
        such right.
      </>
      <>
        <h1 className="font-bold mt-5"> 22. Severance</h1>
        In the event that any provision of this Agreement is declared by any
        applicable law, judicial or other competent authority to be void,
        voidable, illegal or otherwise unenforceable or irrelevant It shall to
        the extent required by such law or authority, be severed from this
        Agreement and rendered ineffective so far as is possible without
        modifying the remaining provisions of this Agreement.
      </>
      <>
        <h1 className="font-bold mt-5"> 23. Further Assurances</h1>
        At all times after the date hereof the Parties shall at their own
        expense execute all such documents and do such acts and things as may be
        reasonably required for the purpose of giving full effect to this
        Agreement.
      </>
      <>
        <h1 className="font-bold mt-5"> 24. Whole Agreement</h1>
        Save for WayaQuick Terms and Conditions and Payment Scheme Rules of this
        Agreement, this Agreement contains the whole agreement between the
        Parties with respect to the subject matter hereof and supersedes any
        prior written or oral agreement between them in relation to its subject
        matter and the Parties confirm that they have not entered into this
        Agreement upon the basis of any representation that are not expressly
        incorporated herein and the WayaQuick Terms and Conditions.
      </>
      <>
        <h1 className="font-bold mt-5"> 25. Dispute and Dispute resolution </h1>
        <ol>
          {Dispute.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
        <h1 className="font-bold mt-5"> 26. Futher Assurancies</h1>
        This Agreement shall be governed by the Laws of the Federal Republic of
        Nigeria.
      </>
      <>
        <h1 className="font-bold mt-5"> 27. Notices </h1>
        <ol>
          {Dispute.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
        WAYA MULTI LINKS TECHNOLOGY LIMITED 
        Address: 14 Yeye Olofin Street,
        Lekki Phase 1, Lagos, Nigeria. Email: info@wayaquick.com 
        Attention: Managing Director
      </>
      <>
        <h1 className="font-bold mt-5"> 28. Dispute and Dispute resolution </h1>
        <ol>
          {Dispute.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
      </>
      <>
      Where you negotiate a Merchant Services Agreement before or after accepting the terms of this E-Agreement, the executed Agreement will take precedence over this E-Agreement.
      </>
    </div>
  );
}

export default Terms;
