import React from 'react';
import { Navbar, Hero, ProductIntro, Caution, ProductFeatures, ProductFeatures2, DownloadCTA, Footer } from '../components';
import OtherBusinesses from '../components/OtherBusinesses';


function Index() {
    return (
        <div className="homepage">
            <Navbar />
            <Hero />
            {/* <Trusted /> */}
            <ProductIntro />
            <ProductFeatures />
            <ProductFeatures2 />
            {/* <Testimonials /> */}
            <DownloadCTA />
            <Caution />
            <OtherBusinesses />
            <Footer />
        </div>
    )
}

export default Index
