

import config from '../config.json'

function HrBanner({ title, subtitle, serviceImage }) {

    return (
        <div className="w-full px-8 md:px-20 mt-12">
            <div className="bg-gray-50 grid grid-cols-1 lg:grid-cols-2 w-full lg:h-96 rounded-3xl">
                <div className="pl-8 md:pl-10 pr-8 md:pr-0 py-10">
                    <h4 className="title text-center lg:mt-8 lg:text-left text-gray-500 text-4xl font-semibold">{title}</h4>
                    <p className="mt-4 text-gray-500 text-lg">{subtitle}</p>
                    <div className="btn-container mt-5 space-y-3 flex items-center flex-col md:flex-row rounded">
                        <a href={ `${config.CUSTOMER_WEBSITE_URL}` + "/register"} >
                            <button className="btn-register2__ flex items-center justify-center py-4 px-6 gap-2 mt-3 text-white font-semibold bg-primary-theme rounded lg:text-md shadow">
                                Get started
                            <svg width="10" height="12" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.890625 12L6.51563 7L0.890624 2L2.01562 -3.31582e-08L9.89063 7L2.01563 14L0.890625 12Z" fill="white" />
                                </svg>
                            </button>
                        </a>
                        <button class="explore-btn rounded text-sm font-medium rounded shadow">
                            LEARN MORE
                        </button>
                    </div>
                </div>
                <div className="flex lg:justify-end hidden md:flex mt-20 lg:mt-0">
                    <img src={serviceImage} alt="" className="-mt-14 -mr-0" width="540px" />
                </div>
            </div>
        </div>
    )
}

export default HrBanner
