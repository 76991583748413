

import React, {useEffect} from 'react';
import {useLocation} from "react-router";

function ScrollOnRouteChange({children}) {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location])
    return (
        children
    )
}

export default ScrollOnRouteChange
