import React from 'react';
import { Home, Login,  Solution } from './components';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import "./App.css";
// import Dashboard from './pages/dashboard';
// import ForgetPassword from './pages/forget-password';
// import changePin from './pages/change-pin';
import Pricing from './pages/Pricing';
import PCI from './pages/PCI';
import Docs from './pages/Docs';
import DocsGuide from './pages/docs/DocsGuide';
import LifeCycle from './pages/docs/LifeCycle';
import Payment from './pages/docs/Payment';
import MerchantDashboard from './pages/docs/MerchantDashboard';
import PaymentLink from './pages/docs/PaymentLink';
import GetStarted from './pages/docs/GetStarted';
import TestEnv from './pages/docs/TestEnv';
import ApiEnc from './pages/docs/ApiEnc';
import WebIntegration from './pages/docs/WebIntegration';
import MobileIntegration from './pages/docs/MobileIntegration';
import EcommerceIntegration from './pages/docs/EcommerceIntegration';
import Webhook from './pages/docs/Webhook';
import ApiReference from './pages/docs/ApiReference';
import SignatureGenerator from './pages/docs/SignatureGenerator';
import TestCard from './pages/docs/TestCard';
import Register from './pages/register'
import SDK from './pages/SDK';
import Contact from './pages/contact';
import ProductFraud from './pages/ProductFraud';
import ScrollOnRouteChange from "./utils/ScrollOnRouteChange";
import Privacy from './components/privacy';
import Cookies from './components/Cookies';
import Terms from './components/Terms';
import SupportButton from './components/supportButton';


function App() {
    return (
        <Router>
            <ScrollOnRouteChange>
                <div className='mb-5'>
                    <Route path="/" exact strict component={Home} />
                    <Route path="/solution" exact strict component={Solution} />
                    <Route path="/contact" exact strict component={Contact} />
                    <Route path="/pricing" exact strict component={Pricing} />
                    <Route path="/compliance" exact strict component={PCI} />
                    
                    <Route path="/get-started" exact strict component={Docs} />
                    <Route path="/registration-guide" exact strict component={DocsGuide} />
                    <Route path="/payment-life-cycle" exact strict component={LifeCycle} />
                    <Route path="/payment" exact strict component={Payment} />
                    <Route path="/merchant-dashboard" exact strict component={MerchantDashboard} />
                    <Route path="/payment-link" exact strict component={PaymentLink} />
                    <Route path="/dev-get-started" exact strict component={GetStarted} />
                    <Route path="/test-environment" exact strict component={TestEnv} />
                    <Route path="/api-encryption" exact strict component={ApiEnc} />
                    <Route path="/web-integration" exact strict component={WebIntegration} />
                    <Route path="/mobile-integration" exact strict component={MobileIntegration} />
                    <Route path="/ecommerce-integration" exact strict component={EcommerceIntegration} />
                    <Route path="/webhooks" exact strict component={Webhook} />
                    <Route path="/api-reference" exact strict component={ApiReference} />
                    <Route path="/signature-generation" exact strict component={SignatureGenerator} />
                    <Route path="/test-card-information" exact strict component={TestCard} />

                    <Route path="/plugin-sdk" exact strict component={SDK} />
                    <Route path="/product-fraud" exact strict component={ProductFraud} />
                    <Route path="/privacy" exact strict component={Privacy} />
                    <Route path="/Cookies" exact strict component={Cookies} />
                    <Route path="/Terms" exact strict component={Terms} />




                    
                    {/* <Route path="/forget-password" exact strict component={ForgetPassword} />
                    <Route path="/change-pin" exact strict component={changePin} /> */}
                    {/* <Route path="/dashboard" exact strict component={Dashboard} /> */}
                </div>
                <SupportButton/>
            </ScrollOnRouteChange>
        </Router>
    )
}

export default App
