import React from 'react';
import {NavLink} from 'react-router-dom';

function Sidebar() {
  const contents = [
        {title: "", children: [
            {text: "Getting started", link: "/get-started"},
        ]},
        {title: "WAYAQUICK OVERVIEW", children: [
            {text: "Registration Guide", link: "/registration-guide"},
            {text: "Payment Life cycle", link: "/payment-life-cycle"},
            {text: "Payment", link: "/payment"},
            {text: "WayaQuick merchant dashboard", link: "/merchant-dashboard"},
            {text: "WayaQuick Payment Links", link: "/payment-link"},
        ]},
        {title: "WAYAQUICK DEVELOPER’S HUB", children: [
            {text: "Getting Started", link: "/dev-get-started"},
            {text: "Test Environment", link: "/test-environment"},
            {text: "API Encryption Key Guide", link: "/api-encryption"},
            {text: "Integration", link: "/web-integration"},
            // {text: "Mobile integration", link: "/mobile-integration"},
            // {text: "ecommerce integration", link: "/ecommerce-integration"},
            // {text: "Webhooks", link: "/webhooks"},
            // {text: "API Reference Guide", link: "/api-reference"},
            // {text: "Signature Generation and Verification", link: "/signature-generation"},
            {text: "Test card information", link: "/test-card-information"},
        ]},
    ]
  return (
    <div className="documentation-sidebar overflow-y-scroll bg-light-theme">
      <ul className="list-none p-6 flex flex-col gap-3 mb-10">
        {
          contents.map(({title, children}, index)=> (
            <>
              <h5 className="text-sm text-gray-600 font-medium uppercase">{title}</h5>
              {
                children.map(({text, link}, index2) => (
                    <li key={index+index2} className="text-gray-500">
                      <NavLink to={`${link}`} activeClassName={`text-primary-theme text-sm`} className="text-gray-500 text-sm">{text}</NavLink>
                    </li>
                ))
              }
            </>
          ))
        }
      </ul>
    </div>
  )
}

export default Sidebar
