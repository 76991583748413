import React from 'react';
import { Footer, Navbar } from '../components';
import ProductFraudPage from "../components/ProductFraudPage";

function ProductFraud() {
    return (
        <div>
           <Navbar />
            <ProductFraudPage/>
            <Footer />
        </div>
    )
}

export default ProductFraud
