

import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {MenuIcon} from '../../assets/MenuIcon';
import Navbar from '../../components/documentation/Navbar.jsx';
import Sidebar from '../../components/documentation/Sidebar.jsx';
import SDKPage from '../../components/SDKPage';
import docContents from '../../components/documentation/content.json';
import {ArrowRight, ArrowLeft} from '../../assets/index';
import GetStartedImage from "../../assets/docs/get-started.png";
import RightNav from '../../components/documentation/RightNav.jsx';


function LifeCycle() {
    const [activeTab, setActiveTab] = useState('0');
    const [paymentTab, setPaymentTab] =useState("payment-link");

    const contents = ["Payment Life Cycle", "Customer Order Created", "Send Payment Details", "Authorization of Payment", "Settlementn", "Refund"];
    

    const paymentProceedures={
        paymentLink: [
            "Login into your dashboard and navigate to Collect Payments > Payment Links.",
            "Click on Create new payment link.",
            "Select Single charge",
            "Fill in the required details, you can leave the amount section empty to allow your customers to enter the amount to be paid or input an amount so your customers pay a fixed amount as you've set. To add more features to the payment link, click on the customise your payment link option.",
            "We create your payment link and you can now copy and share with your customers!",
            "When your customers click on the link, the Flutterwave payment modal will be displayed for them to supply their payment details.",
            "We charge the provided payment details and credit you."
        ]
    }

    console.log(docContents);

    const setToActive = (e) => {
        setActiveTab(e.target.id);
    }
    return (
        <div>
            <Navbar />
            <div className="w-full flex">
                <Sidebar/>
                <div className="documentation-container px-8 py-4 flex items-start">
                    <div className="content">
                        <h5 id={contents[0]} className="title w-full font-medium">Payment Life Cycle</h5>
                        <p className="paragraph py-2">{"Here is the sequence through which the online payment flow occurs when any transaction is initiated by the customer:"}</p>

                        <p id={contents[1]} className="subTopic py-2">{"1. Customer Order Created"}</p>
                        <p className="paragraph py-2">{"When the customer selects the items and fills the checkout page at that time the Transaction ID is created and simultaneously there is one WayaQuick Transaction ID  created after the information is sent to WayaQuick API."}</p>

                        <p id={contents[2]} className="subTopic py-2">{"2. Send Payment Details"}</p>
                        <p className="paragraph py-2">{"After that when the customer proceeds with the payment; enters the details and selects the payment options and clicks on the Pay Now option then these details are sent to the WayaQuick server in a secured manner."}</p>

                        <p id={contents[3]} className="subTopic py-2">{"3. Authorization of Payment"}</p>
                        <p className="paragraph py-2">{"There is an internal process in which WayaQuick sends the authentication request for the customer’s payment deduction from the customer’s bank and after the authorization is received from the bank the same amount is deducted and being retained by WayaQuick."}</p>
                        <p className="paragraph py-2">{"Once the bank has authorized the customer’s payment, it will be auto captured and verifies that purchase as complete by the merchant. Then no further action is needed from the merchant side."}</p>

                        <p id={contents[4]} className="subTopic py-2">{"4. Settlement:"}</p>
                        <p className="paragraph py-2">{"After the funds are captured by WayaQuick, it is settled according to the predefined settlement (payout) cycle (T + 1, where T is the Transaction Date) in the Merchant’s bank account. After the funds are credited in the Merchant’s Bank Account, they are marked as settled."}</p>
                        
                        <p id={contents[5]} className="subTopic py-2">{"5. Refund: "}</p>
                        <p className="paragraph py-2">{"When the payment made by the customer needs to be reversed and given back to them due to any reason then it is known as the Refund. All the settled funds, as well as the successful transactions, could be refunded by the Merchant/Service Provider. "}</p>                        

                        <div className="grid grid-cols-2 gap-5">
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/registration-guide"><ArrowLeft /></Link>
                                <Link to="/registration-guide" className="flex flex-col">
                                    <p className="text-sm text-gray-500 text-right"><span className="text-gray-700">Previous</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium text-right">Registraition Guide</h5>
                                </Link>
                            </div>
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/payment" className="flex flex-col">
                                    <p className="text-sm text-gray-500"><span className="text-gray-700">Next</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium">Payment</h5>
                                </Link>
                                <Link to="/payment"><ArrowRight /></Link>
                            </div>
                        </div>
                        
                    </div>
                    <RightNav contents={contents} />
                </div>
            </div>
        </div>
    )
}

export default LifeCycle;
