import React, { useEffect } from "react";
import { Link } from 'react-router-dom';


function Privacy() {
  let dataInfo = [
    "Accurate and kept up-to-date",
    "Collected fairly and for lawful purposes only",
    "Processed by the Wayaquickcompany within its legal and moral boundaries",
    "Protected against any unauthorized or illegal access by internal or external parties",
  ];

  let notDataInfo = [
    "To Empower the individual: WayaQuick wants you to be in charge of your personal information and to make your own voluntary choices about your Personal Information;",
    "To keep and secure personal information: WayaQuick does not take your trusting us with your information for granted. We take responsibility to ensuring that appropriate security measures are put in place and your personal information is protected;",
    "To be transparent and to educate users: For you to know what personal information is, how we collect personal information, for what purpose and how we secure personal information;",
    "To abide by local laws: WayaQuick is a global company with local privacy expertise. Our privacy practices may vary among the countries in which we operate to reflect local practices and legal requirements. Specific privacy notices may apply to some of our products and services. Please visit the webpage or digital asset of the specific product or service to learn more about our privacy and information practices in relation to that product or service;",
    "To collect and store personal information on the “need to collect” basis: WayaQuick collects personal information to perform its services for you. We work to have measures in place to prevent collecting and storing Personal Information beyond what we need.",
  ];

  let obligations = [
    "Personal Information we may collect about you;",
     "How we get your Personal Information and why we have it;",
    "How we may use your Personal Information;",
    "Data security and retention",
    "Disclosing your Personal Information;",
    "Marketing",
    "Your data protection rights and choices",
    "Cookies",
    "Use by Minors",
    "Data Protection Officer",
     "Training",
    "Data Protection Audit",
    "Dispute Resolution and Filing a Complaint",
    "Updates to our privacy policy",
     "Contact Us",
  ];

  let commitment = [
    "Identity Data: Information such as, your full name(s), your government-issued identity number, and your date of birth. This data is to enable us to verify your identity in order to offer our services to you;",
    "In order to experience the full range of Barter's seamless services including the ability to send funds to your phone contacts through Barter, we would require your consent granting access to your geo-location and your contacts. You have the right to withdraw your consent allowing us access to your contacts at any time. Please note that where your consent is not given or withdrawn, we would require the email address or contact of the recipient, in order to facilitate the fund transfer through Barter.",
    "Contact Data: This is data that is needed to reach out to you, such as your contact address, email address, telephone number, details of the device you use and billing details;",
    "Identification documents :(such as your passport or any Government-issued identity card), a photograph (if applicable) and any other registration information you may provide to prove you are eligible to use our services and in compliance with regulatory requirements on Know Your Customer (KYC);",
    " Log/Technical information: When you access WayaQuick services, our servers automatically record information that your browser sends whenever you visit a website, links you have clicked on, length of visit on certain pages, unique device identifier, log-in information, location and other device details.",
    " Financial Data: Information, such as Bank Verification Number (BVN), personal account number, the merchant’s name and location, the date and the total amount of transaction, and other information provided by financial institutions or merchants when we act on their behalf;",
    "Transactional Data: These are information relating to a payment when you as a merchant (using one or more of our payment processing services) or as a customer, are using our products or services;",
    "Marketing and Communications Data: This includes both a record of your decision to subscribe or to withdraw from receiving marketing materials from us or from our third parties.",
    "Records of your discussions with us, if we contact you and if you contact us.We may also collect, store, use and transfer non-personal information or anonymized data such as statistical or demographic data.As a principle, we do not collect any Special Categories of Personal Information. If we do collect Special Categories of Personal Information, we will ensure compliance with applicable law.This Privacy Policy applies to WayaQuick services only. We do not exercise control over the sites displayed or linked from within our various services. These other sites may place their own cookies, plug-ins or other files on your computer, collect data or solicit personal information from you. WayaQuick does not control these third-party websites and we are not responsible for their privacy statements. Please consult such third parties’ privacy statements and set appropriate controls from your setting when using any third-party services.",
  ];
  let information = [
   "Sign up for a WayaQuick Account;",
    "Use any of our services;",
    "Contact our customer support team;",
    "Fill our online forms;",
    "Contact us;",
    "Your consent; Where you agree to us collecting your Personal Information by using our Services.",
    "We have a contractual obligation: Without your Personal Information, we cannot provide our Services to you.",
    "We have a legal obligation: To ensure we are fully compliant with all applicable Financial legislations such as Anti-Money Laundering and Counter Terrorist Financing Laws, we must collect and store your Personal Information.We protect against fraud by checking your identity with your Personal Information.",
  ]
  let personal = [
    "Create and manage any accounts you may have with us, verify your identity, provide our services, and respond to your inquiries;",
    "Process your payment transactions (including authorization, clearing, chargebacks and other related dispute resolution activities);",
    "Protect against and prevent fraud, unauthorized transactions, claims and other liabilities;",
    "Provide, administer and communicate with you about products, services, offers, programs and promotions of WayaQuick, financial institutions, merchants and partners;",
    "Evaluate your interest in employment and contact you regarding possible employment with WayaQuick;",
    "Evaluate and improve our business, including developing new products and services;",
    "To target advertisements, newsletter and service updates;",
    "As necessary to establish, exercise and defend legal rights;",
    "As may be required by applicable laws and regulations, including for compliance with Know Your Customers and risk assessment, Anti-Money Laundering, anti-corruption and sanctions screening requirements, or as requested by any judicial process, law enforcement or governmental agency having or claiming jurisdiction over WayaQuick or WayaQuick's affiliates;",
    "To use data analytics to improve our Website, products or services, user experiences and to optimize service",
    "For other purposes for which we provide specific notice at the time of collection.",
  ]
  let personal1 = [
    "We have your consent. We require opt-in consent for the sharing of any personal information; We share Personal Information with third parties directly authorized by you to receive Personal Information, such as when you authorize a third party application provider to access your account. The use of your Personal Information by an authorized third party is subject to the third party's privacy policy and WayaQuick shall bear no liability for any breach which may arise from such authorization by you",
    "We provide such information to our subsidiaries, affiliated companies or other trusted businesses or persons for the purpose of processing personal information on our behalf. We require that these parties agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.",
    "We have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to:",
    "a. satisfy any applicable law, regulation, legal process or enforceable governmental request,",
    "b. enforce applicable Terms of Service, including investigation of potential violations thereof,",
    "c. detect, prevent, or otherwise address fraud, security or technical issues, or",
    "d. protect against imminent harm to the rights, property or safety of WayaQuick, its users or the public as required or permitted by law.",
    "If WayaQuick becomes involved in a merger, acquisition, or any form of sale of some or all of its assets, we will provide notice before personal information is transferred and becomes subject to a different privacy policy.",
  ]
  let protection = [
     "Right to be informed.",
    "Right to request access or copies to your Personal Information by signing into your Account or contacting us.",
    "Right to request that WayaQuick erase your Personal Information. You have the right to ask us to erase your Personal Information. Please note that this is a limited right which applies where the data is no longer required, or the processing has no legal justification. The exceptions to this right is where the applicable law requires WayaQuick to retain a historical archive or where we retain a core set of your Personal Information to ensure we do not inadvertently contact you in future where you object to your data being used for marketing purposes.",
    "Right to correct or rectify any Personal Information that you provide which may be incorrect, out of date or inaccurate. You also have the right to ask us to complete information you think is incomplete.",
    "Right to object to the processing of your Personal Information for marketing purposes. You have a right to ask us not to contact you for marketing purposes by adjusting your notification preference on the settings page or by opting out via the unsubscribe link in marketing emails we send you.",
    "Right to object to processing: You have the right to object to the processing of your Personal Information in certain circumstances. Please note that where you object to us processing your Personal Information, we might be unable to provide the services to you.",
  ]
  let Audit = [
    "WayaQuick shall conduct an annual data protection audit through a licensed Data Protection Compliance Organization (DPCOs) to verify WayaQuick’s compliance with the provisions of the NDPR and other applicable data protection laws.",
    "The audit report will be certified and filed by the DPCO to the National Information Technology Development Agency (NITDA) as required under the NDPR.",
  ]
  return (
    <div className="px-8 lg:px-40 pb-20">
      <h4 className="title text-3xl font-semibold px-8 text-center md:px-0 my-16">
        Wayaquick Company data protection policy
      </h4>
      <div>
        <h2 className="font-semibold"> Introduction</h2>
        <h5>
          We are Wayamultilinks Technology Limited (“WayaQuick”), a Nigerian
          Company with offices at No. 14, Yeye Olofin Street, Lekki Lagos with
          company number 1532218. For any access request, questions, or
          inquiries about how we use your Personal Information, please contact
          us at <a href="mailto:privacy@wayaquick.com">privacy@wayaquick.com</a> WayaQuick does not take your trusting us
          with your information for granted. The privacy of our Users is
          important to us and we are committed to safeguarding it. Hence, this
          Privacy Policy explains your Personal Information which we collect,
          why we collect it, and what we do with it.
        </h5>
        <h1 className="font-bold mt-3">Overview</h1>
        This Privacy Policy describes your privacy rights regarding how and when
        we collect, use, store, share and protect your information across our
        website, payment platforms (“Platforms”), APIs, Software Applications,
        (“Apps”), email notifications and tools regardless of how you use or
        access them. This Privacy Policy is applicable to all of the Website,
        software applications (“Apps”) and/or payment platforms (“Platforms”)
        offered by WayaQuick or affiliated companies collectively referred to as
        WayaQuick “services”. WayaQuick is part of a group which is made up of a
        number of local operating entities in a number of markets across the
        globe. “WayaQuick”, “we” “us” or our” in this privacy statement means
        the WayaQuick entity that is responsible for processing your personal
        information.
      </div>
      <div className="mt-5">
        <h2 className="font-bold">Defination</h2>
        Account: means a Wayaquick or WayaQuick Account. Cookies: A cookie is a
        small data file that is transferred to your computer or mobile device.
        It enables us to remember your account log-in information, IP addresses,
        web traffic, number of times you visit, browser type and version, device
        details, date and time of visits. WayaQuick: means Wayamultilinks
        Technology Limited. Personal Information: Any information that can be
        used to identify a living person including email address, company name,
        password, payment card, financial information such as Bank Verification
        Number (BVN), bank account number, etc.), Government- issued Identity
        card, and/or taxpayer identification it may also include anonymous
        information that is linked to you, for example, your internet protocol
        (IP), log-in information, address, location, device or transaction data.
        Sites: means any platform including but not limited to mobile
        applications, websites and social media platforms. User: means an
        individual who uses the Services or accesses the Sites and has agreed to
        use the end services of WayaQuick. Special Categories of Personal
        Information means details about your race or ethnicity, religious or
        philosophical beliefs, sex life, sexual orientation, political opinions,
        trade union membership, information about your health and genetic and
        biometric data.
      </div>

      <div className="mt-5">
        <h2 className="font-bold"> Objectives </h2>
        This Privacy Policy statement is to provide all persons and
        organizations whose Personal Information we hold with description of the
        types of Personal Information we collect, the purposes for which we
        collect that Personal Information, the other parties with whom we may
        share it and the measures we take to protect the security of the data.
        Accordingly, we have developed this privacy policy in order for you to
        understand how we collect, use, communicate, disclose and otherwise make
        use of personal information. We have outlined our privacy policy below.
        {/* <ul>
          {dataInfo.map((e) => (
            <li>{e}</li>
          ))}
        </ul> */}
      </div>
      <div className="mt-5">
        <h1 className="font-bold">Our Privacy Principles:</h1>
        WayaQuick focuses on the following core principles
        <ul>
          {notDataInfo.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </div>
      <>
      <h1 className="font-bold">Content</h1>
        {/* In addition to ways of handling the data the WayaquickCompany has direct
        obligations towards people to whom the data belongs. Specifically we
        must: */}
        <ul>
          {obligations.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </>

      <>
        <h1 className="font-bold mt-5">1. Personal Information We May Collect About You </h1>
        We may collect, use, process, store, or transfer personal information such as:
        <ul>
          {commitment.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
        We may also collect, store, use and transfer non-personal information or anonymized data such as statistical or demographic data.
        As a principle, we do not collect any Special Categories of Personal Information. If we do collect Special Categories of Personal Information, 
        we will ensure compliance with applicable law.
        <p>
        This Privacy Policy applies to WayaQuick services only. We do not exercise control over the sites displayed or linked from within our various services. 
        These other sites may place their own cookies, plug-ins or other files on your computer, collect data or solicit personal information from you. WayaQuick does not 
        control these third-party websites and we are not responsible for their privacy statements. Please consult such third parties’ 
        privacy statements and set appropriate controls from your setting when using any third-party services.
        </p>
      </>

      <>
        <h1 className="font-bold mt-5"> 2. How we get your Personal Information and why we have it </h1>
        The Personal Information we have about you is directly made available to us when you:

        <ul>
          {information.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
        The lawful basis we rely on for processing your Personal Information are:
      </>

      <>
        <h1 className="font-bold mt-5"> 3. How We May Use Your Personal Information </h1>
        We may use your Personal Information we collect to:
        <ul>
          {personal.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </>
      <>
        <h1 className="font-bold mt-5"> 4. Data Retention and Security </h1>
        The security of your Personal Information is important to WayaQuick. We are committed to protecting the information we collect.
         We maintain administrative, technical and physical controls designed to protect the Personal Information you provide, or we collect against loss or theft, as well as against any unauthorized access,
         risk of loss, disclosure, copying, misuse or modification.
        <p>
        Other security measures include but not limited to, secure servers, firewall, data encryption and granting access only to employees in order to fulfil their job responsibilities.
        Where you use a password for any of your Accounts, please ensure you do not share this with anyone, and the password is kept confidential at all times.
        We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of your Personal Information is protected and maintained. Transmitting information online is not entirely secure. As such, we cannot guarantee that all information provided online is secure. We would take all reasonable steps to ensure that your Personal Information is secured and well protected.
        We will only retain personal information on our servers for as long as is reasonably necessary as long as we are providing Services to you. Where you close your Account, your information is stored on our servers to the extent necessary to comply with regulatory obligations and for the purpose of fraud monitoring, detection and prevention. Where we retain your Personal Information,
        we do so in compliance with limitation periods under the applicable law.
        </p>
      </>
      <>
        <h1 className="font-bold mt-5"> 5. Disclosing your Personal Information </h1>
        We may disclose or share your Personal Information with third parties which include our affiliates, employees, officers, service providers, agents, suppliers, subcontractors as may be reasonably necessary for the purposes set out in this policy.
        WayaQuick only shares personal information with External Third parties in the following limited circumstances:
        <ul>
          {personal1.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </>
      <>
        <h1 className="font-bold mt-5"> 6. Marketing </h1>
        We may use your Personal Information we collect to:
        <p>
        We may process your Personal Information in order to contact you or send you marketing content and communication about our products, services or surveys. You may exercise your right to object to such contact from us or opt out from the marketing content. Please note however that if you opt-out of marketing content, we may still send you messages relating to transactions and our Services related to our ongoing business relationship.
        We may ask you for permission to send notifications to you. Our Services will still work if you do not grant us
        consent to send you notifications.
        </p>
      </>
      <>
        <h1 className="font-bold mt-5"> 7. Your Data Protection Rights and Choices </h1>
        Based on your location and applicable laws, below are the rights you have as a user in relation to your Personal Information:
        <ul>
          {protection.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
        The basis of we processing your Personal Information is your consent. You also have the choice at any time to withdraw consent which you have provided.

        You also have a choice to deactivate your Account at any time. You may contact us should you wish to de-activate your Account at any time via the <Link to="/contact">Contact Us</Link>segment below or send us an email at <a href= "mailto:privacy@wayaquick.com">privacy@wayaquick.com.</a>

        If you wish to exercise any of the rights set above, please contact us using the contact information provided in the <Link to ="/contact">Contact Us</Link> segment below. Where we are unsure of your identity, we might ask you for proof of your identity for security reasons before dealing your request.
      </>
      <>
        <h1 className="font-bold mt-5"> 8. Cookies </h1>
        Like many other websites, we use cookies to distinguish you from other users and to customize and improve our services.
        Some browsers may automatically accept cookies while some can be modified to decline cookies or alert you when a website wants to place a cookie on your computer. If you do choose to disable cookies, it may limit your ability to use our website. For detailed information on the cookies and how we use them see our <Link to="/cookies">Cookies Policy</Link>.
      </>
      <>
        <h1 className="font-bold mt-5"> 9. Minors </h1>
        WayaQuick’s websites and applications are not directed at persons under the age of eighteen (18) and we do not collect any Personal Information knowingly or directly from minors who fall within this category.
        Where you have any belief that WayaQuick has mistakenly or unknowingly collected information from a minor, please contact us using the information provided under the <Link to="/contact">Contact Us</Link> section to enable investigate and restrict such data.
      </>
      <>
        <h1 className="font-bold mt-5"> 10. International Data Transfer </h1>
        Where Personal Information is to be transferred to a country outside Nigeria, WayaQuick shall put adequate measures in place to ensure the security of such Personal Information and to ensure same is done securely and in accordance with the Nigerian Data Protection Regulation.
      </>
      <>
        <h1 className="font-bold mt-5"> 11. Data Protection Officer </h1>
        WayaQuick has appointed a Data Protection Officer(s) (DPO) responsible for overseeing the Company's data protection strategy and its implementation to ensure compliance with the NDPR requirements. The DPO is knowledgeable on data privacy and protection principles and is familiar with the provisions of the NDPR.
      </>
      <>
        <h1 className="font-bold mt-5"> 12. Training </h1>
        WayaQuick ensures that employees who collect, access and process Personal Information receive adequate data privacy and protection training in order to develop the necessary knowledge, skills and competence required to effectively manage the compliance framework under this Policy and the Nigerian Data Protection Regulation (NDPR) with regard to the protection of Personal Information. On an annual basis, WayaQuick develops a capacity building plan for its employees on data privacy and protection in line with the NDPR.
      </>
      <>
        <h1 className="font-bold mt-5"> 13. Data Protection Audit </h1>
        <ol>
          {Audit.map((e) => (
            <li>{e}</li>
          ))}
        </ol>
        </>
      <>
        <h1 className="font-bold mt-5"> 14. Dispute Resolutions and Filing a Compliant </h1>
        If you have any complaints regarding this Privacy Policy, please contact us at <Link to="/privacy">privacy@wayaquick.com</Link>. We will investigate and work on resolutions for complaints and disputes regarding use and disclosure of personal information within thirty (30) days in accordance with this Privacy Policy and in accordance with the Nigerian Data Protection Regulation.
      </>
      <>
        <h1 className="font-bold mt-5"> 15. Updates to our privacy policy </h1>
        From time to time, we may change, amend or review this Privacy Policy from time to time to reflect new services or changes in our Privacy Policy and place any updates on this page. All changes made will be posted on this page and where changes will materially affect you, we will notify you of this change by placing a notice online or via mail. If you keep using our Services, you consent to all amendments of this Privacy Policy.
      </>
      <>
        <h1 className="font-bold mt-5"> 16. Contact Us: </h1>
        All access requests, questions, comments, complaints and other requests regarding the privacy policy should be sent to <Link to="/privacy">privacy@wayaquick.com</Link>
       We may request additional details from you regarding your complaints and keep records of your requests and resolution.
      </>
    </div>
  );
}

export default Privacy;
