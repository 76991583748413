

import React from 'react'

function Alert({children}) {
  return (
    <div style={{backgroundColor: "#F4F7FA"}} className="w-full my-4 h-auto py-3 text-sm flex items-start gap-2 my-2 px-3 rounded shadow border-l-2 border-blue-300">
      {children}
    </div>
  )
}

export default Alert
