import React from 'react'
import { OtherBusinessesIcon, WayaBankLogo, WayaGramLogo, WayaPosLogo } from '../assets';
import Card from './Card';
import wayabankLog from '../assets/wayaBank.png'
import wayaPos from '../assets/wayaPos.png'
import wayaGram from '../assets/wayagram.png'


function OtherBusinesses() {
    return (
        <div className="w-full px-6">
            <div className="w-full">
                <div className="w-full py-4 h-auto bg-red-50 flex flex-col md:flex-row items-center md:justify-center md:gap-6">
                    <OtherBusinessesIcon/>
                    <span className="font-bold text-xl md:text-2xl pt-3">Explore other Businesses by WayaLinks Limited</span>
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 py-10">                
                <Card logo={<img src={wayabankLog}  width="120px"/>} description="Discover the best way to to carry your financial transactions. Free internet banking and bills payment" name="WayaBank" link={process.env.REACT_APP_WAYABANK_URL} />
                <Card logo={<img src={wayaGram}  width="120px"/>} description="Discover beautiful places, people, conversations, vendors and clients. Chat and call clients, friends and family" name="WayaGram" link={process.env.REACT_APP_WAYAGRAM_URL} />
                <Card logo={<img src={wayaPos} width="120px"/>} description="Accepts MasterCard, VISA and Verve cards payment and perform other mobile banking services at your physical store with Wayapos Terminal POS device." name="WayaPos" link={process.env.REACT_APP_WAYAPOS_URL} />
            </div>
        </div>
    )
}

export default OtherBusinesses
