import React from 'react'

function SignatureGenerator() {
  return (
    <div>
      SignatureGenerator
    </div>
  )
}

export default SignatureGenerator
