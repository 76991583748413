import React, { useEffect } from "react";
import { Link } from 'react-router-dom';


function cookies() {
  let cookie = [
    "Our Cookies: Our cookies are “first-party cookies”, and can be either permanent or temporary. These are necessary cookies, without which the Site won't work properly or be able to provide certain features and functionalities. Some of these may be manually disabled in your browser, but may affect the functionality of the Site.",
    "Personalization Cookies: Personalization Cookies are used to recognize repeat visitors to the Site. We use these cookies to record your browsing history, the pages you have visited, and your settings and preferences each time you visit the Site.",
    "Security Cookies: Security cookies help identify and prevent security risks. We use these cookies to authenticate users and protect user data from unauthorized parties.",
    "Site Management Cookies: Site management cookies are used to maintain your identity or session on the Site so that you are not logged off unexpectedly, and any information you enter is retained from page to page. These cookies cannot be turned off individually, but you can disable all cookies in your browser.",
    "Third-Party Cookies: Third-party cookies may be place on your computer when you visit the Site by companies that run certain services we offer. These cookies allow the third parties to gather and track certain information about you. These cookies can be manually disabled in your browser.",
  ];

  return (
    <div className="px-8 lg:px-40 pb-20">
      <h4 className="title text-3xl font-semibold px-8 text-center md:px-0 my-16">
        Wayaquick Cookies policy
      </h4>
      <div>
        <h2 className="font-semibold"> Introduction</h2>
        <h5>
          WayaQuick (“we” or “us” or “our”) may use cookies, web beacons,
          tracking pixels, and other tracking technologies when you visit our
          website, including any other media form, media channel, mobile
          website, or mobile application related or connected thereto
          (collectively, the “Site”) to help customize the Site and improve your
          experience. We reserve the right to make changes to this Cookie Policy
          at any time and for any reason. We will alert you about any changes by
          updating the “Last Updated” date of this Cookie Policy. Any changes or
          modifications will be effective immediately upon posting the updated
          Cookie Policy on the Site, and you waive the right to receive specific
          notice of each such change or modification. You are encouraged to
          periodically review this Cookie Policy to stay informed of updates.
          You will be deemed to have been made aware of, will be subject to, and
          will be deemed to have accepted the changes in any revised Cookie
          Policy by your continued use of the Site after the date such revised
          Cookie Policy is posted.
        </h5>
        <h1 className="font-bold mt-3">Types Cookies</h1>A “cookie” is a string of
        information which assigns you a unique identifier that we store on your
        computer. Your browser then provides that unique identifier to use each
        time you submit a query to the Site. We use cookies on the Site to,
        among other things, keep track of services you have used, record
        registration information, record your user preferences, keep you logged
        into the Site, facilitate purchase procedures, and track the pages you
        visit. Cookies help us understand how the Site is being used and improve
        your user experience
      </div>
      <>
        <h1 className="font-bold mt-5"> Types of Cookies </h1>
        The following types of cookies may be used when you visit our website.
        <ul>
          {cookie.map((e) => (
            <li>{e}</li>
          ))}
        </ul>
      </>
      <>
        <h1 className="font-bold mt-5"> Control of cookies </h1>
        Most browsers are set to accept cookies by default. However, you can remove or reject cookies in your browser’s settings. Please be aware that such action could affect the availability and functionality of the Site
      </>
      <>
        <h1 className="font-bold mt-5"> Other Tracking Technology </h1>
        [ "In addition to cookies, we may use web beacons, pixel tags, and other tracking technologies on the Site to help customize the Site and improve your experience. A “web beacon” or “pixel tag” is tiny object or image embedded in a web page or email. They are used to track the number of users who have visited particular pages and viewed emails, and acquire other statistical data. They collect only a limited set of data, such as a cookie number, time and date of page or email view, and a description of the page or email on which they reside. Web beacons and pixel tags cannot be declined. However, you can limit their use by controlling the cookies that interact with them." ]
      </>
      <>
        <h1 className="font-bold mt-5"> Privacy Policy </h1>
        For more information about how we use information collected by cookies and other tracking technologies, please refer to our <Link href= "/privacy">Privacy Policy.</Link>This Cookie Policy is part of and is incorporated into our Privacy Policy. By using the Site, you agree to be bound by this Cookie Policy and our Privacy Policy.
      </>
      <>
        <h1 className="font-bold mt-5"> Contact Us </h1>
        All access requests, questions, comments, complaints and other requests regarding the cookies policy should be sent to <a href= "mailto:privacy@wayaquick.com">privacy@wayaquick.com.</a>
        We may request additional details from you regarding your complaints and keep records of your requests and resolution.
        </>
    </div>
  );
}

export default cookies;
