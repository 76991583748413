
import { useEffect, useState } from 'react';
import Rocket from '../assets/big-rocket.svg';
import AOS from 'aos';
import "aos/dist/aos.css";
import { HrBanner } from '.';
import { Link } from 'react-router-dom';
import serviceImage1 from '../assets/hero-section.png';
import serviceImage2 from '../assets/wayapay-pos.svg';

function ProductIntro() {
    const [title, setTitle] = useState("Accept Payments Anytime, Anywhere");
    const [subtitle, setSubtitle] = useState("The simplest way to accept payments from customers  anytime, anywhere via several methods including but not limited to; Credit/debit card, Bank Account, USSD, PayAttitude, Wallet etc")
    const [serviceImage, setServiceImage] = useState(serviceImage1)
    useEffect(() => {
        AOS.init({
            duration: 1500
        });
    }, []);

    const toggleSwitch = (e) => {
        document.querySelectorAll(".product_switch").forEach(switchBtn => {
            switchBtn.classList.remove("bg-primary-theme");
            switchBtn.classList.remove("text-white");
            switchBtn.classList.add("text-gray-400");
        });
        e.target.classList.add("bg-primary-theme");
        e.target.classList.add("text-white");
        e.target.classList.remove("text-gray-400");

        if (e.target.id === "1") {
            setTitle("Wayapay Web");
            setSubtitle("Sell online with an ecommerce website, Mobile App and more.");
            setServiceImage(serviceImage1)
        } else if (e.target.id === "2") {
            setTitle("Wayapay POS");
            setSubtitle("Sell in person at your store locations, pop-ups, concerts.");
            setServiceImage(serviceImage2)
        }
    }

    return (
        <div className="flex flex-col py-8 px-8 md:px-0">
            <div className="w-full overflow-x-hidden h-auto md:h-auto grid grid-cols-1 md:grid-cols-2 md:pb-16">
                <div className="h-100 flex w-full justify-center md:justify-end items-center mb-8">
                    <img data-aos="fade" src={Rocket} alt="product_rocket" className="mx-8 md:mr-20 w-full md:w-3/5 ..." />
                </div>
                <div className="w-full md:w-4/5 pr-10 flex flex-col items-start justify-center pb-5 ...">
                    <h4 data-aos="fade-up" className="title md:text-3xl md:mt-0 font-semibold">Get onboarded and begin accepting payments in no time.</h4>
                    <ul className="mt-5">
                        <li data-aos="fade-up" data-aos-delay="300" className="flex items-center gap-3 my-4">
                            <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="35" height="33" rx="10" fill="#FFECE6" />
                                <path d="M25 11L14 22L9 17" stroke="#FF6700" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className="w-full ..."><span className="text-primary-theme underline"><Link to="/register">Register</Link></span> account with us.</p>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="400" className="flex items-center gap-3 my-4">
                            <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="35" height="33" rx="10" fill="#FFECE6" />
                                <path d="M25 11L14 22L9 17" stroke="#FF6700" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className="w-full text-md ...">Perform test mode transactions by using test API keys.</p>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="500" className="flex items-center gap-3 my-4">
                            <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="35" height="33" rx="10" fill="#FFECE6" />
                                <path d="M25 11L14 22L9 17" stroke="#FF6700" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className="w-full ...">Submit compliance form</p>
                        </li>
                        <li data-aos="fade-up" data-aos-delay="600" className="flex gap-3 my-4">
                            <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="35" height="33" rx="10" fill="#FFECE6" />
                                <path d="M25 11L14 22L9 17" stroke="#FF6700" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <p className="w-full ...">Start collecting real-life payment from your customers either by integrating with our platforms or using our payment link feature.</p>
                        </li>
                    </ul>

                    <div data-aos="fade-up" data-aos-delay="800" class="btn-container mt-4 pl-12 flex flex-col md:flex-row rounded">
                        <Link to="/register">
                            <button class="btn-register2__ flex items-center justify-center py-3 px-4 text-white font-semibold bg-primary-theme rounded lg:text-lg lg:-ml-12 shadow">
                                REGISTER NOW
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="white" />
                                </svg>
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="flex flex-col items-center mt-20 md:mt-0">
                {/* <h4 data-aos="fade-up" data-aos-delay="300" className="title2 md:mt-12 text-primary-theme text-4xl font-semibold">Get started with Wayapay</h4> */}

                <div className="w-full md:w-2/5 lg:px-20 hidden">
                    <div data-aos="fade-up" data-aos-delay="300" className="toggle mt-6 border border-gray-100 flex items-center w-full h-14 rounded-full bg-gray-100 relative ...">
                        <div onClick={toggleSwitch} id="1" className="product_switch cursor-pointer w-full h-14 flex items-center justify-center rounded-full bg-primary-theme text-white text-sm md:text-lg font-semibold ...">
                            Wayapay Web
                        </div>
                    </div>
                </div>

                <HrBanner title={title} subtitle={subtitle} serviceImage={serviceImage} />
            </div>
        </div>
    )
}

export default ProductIntro
