import React from 'react'
import ProductList from './ProductList';
import PayLink from "../assets/paylink.svg";
import Subscription from "../assets/subscription.svg";
import TestAndLive from "../assets/test_and_live_mode.svg";
import Mobile from "../assets/mobile.svg";
import Checkout from "../assets/checkout.svg";

function Product() {
    return (
        <React.Fragment>
            <h4 className="title lg:text-3xl text-center font-bold mt-16">Our amazing Solutions</h4>
            <ProductList image={PayLink} title="Payment Link" subtitle="Don't have a an app or website for selling? Now let your customers pay online with payment links. Access the WayaQuick dashboard now, create and share payment link via an email, SMS, messenger, chatbot etc. and get paid immediately." type="1"/>

            {/* <ProductList image={Subscription} title="Subscription" subtitle="Enable recurring payment schedule, control the billing cycle and get instant alerts on subscription activity with WayaQuick's Subscriptions. All you have to do is to link a plan to the customer. Let us handle everything else for you." type="2"/> */}

            <ProductList image={TestAndLive} title="Test and Live Mode" subtitle="Experiment with various features without fear of accidentally spending real money. It's a great place to try out new tools and see how customer-facing features look before you publicize them." type="1"/>

            <ProductList image={Mobile} title="Dashboard Reporting" subtitle="Real-time data and insights on your WayaQuick's Dashboard to make informed business decisions." type="2"/>

            <ProductList image={Checkout} title="Instant Settlements" subtitle="Get cash in hand instantly after accepting online payments with Instant Settlements whenever you want. You don't need to wait for days to get your own customer payments to grow your business with WayaQuick." type="1"/>
        </React.Fragment>
    )
}

export default Product
