import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco, code } from 'react-syntax-highlighter/dist/esm/styles/hljs';
export const CodeHighlighter = ({ lang, code }) => {
	const language = lang ? lang : 'javascript';
	const codeString = code;
	// style=dark
	return (
		<SyntaxHighlighter language={language} style={docco} showLineNumbers>
			{codeString}
		</SyntaxHighlighter>
	);
};
