import React,{ useState }  from 'react'

function RightNav({contents}) {
    const [ activeTab, setActiveTab ] = useState('0');

    const setToActive = (e) => {
		setActiveTab(e.target.id);
	};
  return (
    <div style={{width: "240px"}} className="sticky ml-auto top-28 pl-10">
        <ul className="border-l border-gray-300 flex flex-col gap-3">
            <p className="list-none px-5 uppercase flex items-center gap-3">
                {/* <MenuIcon /> */}
                CONTENTS
            </p>
            {contents.map((content, index) => (
                <li key={index} className="flex">
                    <a
                        id={index}
                        onClick={setToActive}
                        href={'#' + content}
                        className={`${activeTab === JSON.stringify(index)
                            ? 'text-primary-theme border-l-4 border-primary-theme -ml-0.5'
                            : 'text-gray-700'} pl-4 text-sm  font-medium`}
                    >
                        {content}
                    </a>
                </li>
            ))}
        </ul>
    </div>
  )
}

export default RightNav
