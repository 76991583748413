

import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {MenuIcon} from '../../assets/MenuIcon';
import Navbar from '../../components/documentation/Navbar.jsx';
import Sidebar from '../../components/documentation/Sidebar.jsx';
import SDKPage from '../../components/SDKPage';
import docContents from '../../components/documentation/content.json';
import {ArrowRight, ArrowLeft} from '../../assets/index';
import GetStartedImage from "../../assets/docs/get-started.png";
import RightNav from '../../components/documentation/RightNav.jsx';


function MerchantDashboard() {
    const [activeTab, setActiveTab] = useState('0');
    const [paymentTab, setPaymentTab] =useState("payment-link");

    const contents = ["WayaQuick Merchant Dashboard", "Different Payment Methods"];
    

    const paymentProceedures={
        paymentLink: [
            "Login into your dashboard and navigate to Collect Payments > Payment Links.",
            "Click on Create new payment link.",
            "Select Single charge",
            "Fill in the required details, you can leave the amount section empty to allow your customers to enter the amount to be paid or input an amount so your customers pay a fixed amount as you've set. To add more features to the payment link, click on the customise your payment link option.",
            "We create your payment link and you can now copy and share with your customers!",
            "When your customers click on the link, the Flutterwave payment modal will be displayed for them to supply their payment details.",
            "We charge the provided payment details and credit you."
        ]
    }

    console.log(docContents);

    const setToActive = (e) => {
        setActiveTab(e.target.id);
    }
    return (
        <div>
            <Navbar />
            <div className="w-full flex">
                <Sidebar/>
                <div className="documentation-container px-8 py-4 flex items-start">
                    <div className="content">
                        <h5 id={contents[0]} className="title w-full font-medium">WayaQuick Merchant Dashboard</h5>
                        <p className="paragraph py-2">{"To understand the dashboard functions and features well let us take a trip and get to know about each and every part of your WayaQuick Dashboard. The Merchant Dashboard can be opened from the WayaQuick Dashboard Login. Click on ‘Go to’ under the ‘Payment Gateway’ section."}</p>

                        <p id={contents[1]} className="subTopic py-2">{"Different Payment Methods"}</p>
                        <p className="paragraph py-2">{"The customers can be given the various payment options with the gateway checkout. They are allowed to pay with whichever payment method they want from Debit Cards-Credit Cards (Master Card, Visa, Verve, America Express, Union Pay, Discover, RuPay etc ), Wallets, USSD, PayAttitude etc. The customer’s dropping off at the checkout is mainly due to non-availability of their preferred payment option or an alternative payment option. They would need an alternate payment method if any of the other is facing down time. At that time, the customer would leave the site without making the payment. So it is always best if they can pay in any other way. For example, if Debit Card faces any issues then the customer could pay with the wallet."}</p>

                                                

                        <div className="grid grid-cols-2 gap-5">
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/payment"><ArrowLeft /></Link>
                                <Link to="/payment" className="flex flex-col">
                                    <p className="text-sm text-gray-500 text-right"><span className="text-gray-700">Previous</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium text-right">Payment</h5>
                                </Link>
                            </div>
                            <div className="w-full mt-4 h-20 px-4 flex items-center justify-between border border-gray-300 rounded-lg">
                                <Link to="/payment-link" className="flex flex-col">
                                    <p className="text-sm text-gray-500"><span className="text-gray-700">Next</span></p>
                                    <h5 className="text-gray-700 text-lg font-medium">Payment Link</h5>
                                </Link>
                                <Link to="/payment-link"><ArrowRight /></Link>
                            </div>
                        </div>
                        
                    </div>
                    <RightNav contents={contents} />
                </div>
            </div>
        </div>
    )
}

export default MerchantDashboard;
