

import React from 'react'

function PricingPage() {
    return (
        <div>
            <div className="bg-primary-theme md:h-96 pt-14 pb-14 md:pb-0 w-full">
                <div className="flex justify-center items-center">
                    <p className="text-white text-sm flex items-center gap-2">Pricing <span style={{backgroundColor: "#FF8F66",}} className="px-4 flex items-center justify-center py-2 rounded text-xs">Nigeria</span></p>
                </div>
                <h4 className="text-3xl mt-10 md:mt-0 md:text-4xl px-5 md:px-0 text-white font-semibold text-center">Simple, transparent pricing</h4>
                <p className="text-sm px-10 my-2 w-full md:w-1/3 text-gray-100 mx-auto text-center">WayaQuick provides fair, simple and transaparent pricing. No surprises, no hidden charges.</p>

                <div className="pricing-card relative gap-5 z-10 pt-10 flex flex-col md:flex-row justify-center px-10">
                    <div className="w-full md:w-1/2 lg:w-2/5 xl:w-1/5 h-60 rounded bg-white relative py-2 ...">
                        <span className="bg-primary-theme text-xs text-white m-2 px-2 py-2 rounded">For Local Cards</span>
                        <h5 className="font-semibold text-xl mx-2 mt-4">1.5% per transaction</h5>
                        <div className="w-full h-32 bg-gray-50 rounded absolute bottom-0 left-0">
                            <p className="text-xs flex gap-2 p-2">
                                    <span className="w-10 h-10"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="14" cy="14" r="14" fill="#FF6700"/>
                                        <path d="M20 10.666L11.75 18.666L8 15.0297" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                Local transactions fees are capped at ₦2000, meaning that's the absolute maximum you'll ever pay in fees per transactions
                            </p>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 lg:w-2/5 xl:w-1/5 h-60 rounded bg-white relative py-2 ...">
                        <span className="bg-primary-theme text-xs text-white m-2 px-2 py-2 rounded">For International Cards</span>
                        <h5 className="font-semibold text-xl mx-2 mt-4">3.9% per transaction</h5>
                        <div className="w-full h-32 bg-gray-50 rounded absolute bottom-0 left-0">
                            <p className="text-xs flex gap-2 p-2">
                                    <span className="w-10 h-10"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="14" cy="14" r="14" fill="#FF6700"/>
                                        <path d="M20 10.666L11.75 18.666L8 15.0297" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                    Get paid by your customers from all over the world.
                            </p>
                            <p className="text-xs flex gap-2 p-2">
                                    <span className="w-10 h-10"><svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="14" cy="14" r="14" fill="#FF6700"/>
                                        <path d="M20 10.666L11.75 18.666L8 15.0297" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </span>
                                    International cards are charged and settled in Naira by default, but you can also choose to get settled in USD.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-auto md:h-40 bg-gray-100 w-full pt-32 px-5 md:px-10">
                {/* <div className="transfer-charges border border-gray-200 p-5 bg-white h-auto w-full md:w-1/2 mx-auto">
                    <h5 className="font-semibold text-xl">Transfers</h5>
                    <hr />
                    <div className="flex justify-between py-3">
                        <p className="flex items-center text-sm gap-3">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="14" cy="14" r="14" fill="#FF6700"/>
                            <path d="M20 10.666L11.75 18.666L8 15.0297" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Transfers of NGN 5,000 and below</p>
                        <p className="text-sm">NGN 10 per Transfer</p>
                    </div>
                    <hr />
                    <div className="flex justify-between py-3">
                        <p className="flex items-center text-sm gap-3">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="14" cy="14" r="14" fill="#FF6700"/>
                            <path d="M20 10.666L11.75 18.666L8 15.0297" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Transfers between NGN 5,001 and NGN 50,000</p>
                        <p className="text-sm">NGN 10 per Transfer</p>
                    </div>
                    <hr />
                    <div className="flex justify-between py-3">
                        <p className="flex items-center text-sm gap-3">
                        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="14" cy="14" r="14" fill="#FF6700"/>
                            <path d="M20 10.666L11.75 18.666L8 15.0297" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        Transfers of NGN 5,000 and below</p>
                        <p className="text-sm">NGN 10 per Transfer</p>
                    </div>
                </div>

                <div className="h-auto md:h-screen bg-gray-100 w-full pt-10 pb-10 md:pb-0">
                    <div className="verification-charges border border-gray-200 p-5 bg-white h-auto w-full md:w-1/2 mx-auto">
                        <h5 className="font-semibold text-xl">User verification</h5>
                        <hr />
                        <div className="flex justify-between py-3">
                            <p className="flex items-center text-sm gap-3">
                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="14" cy="14" r="14" fill="#FF6700"/>
                                <path d="M20 10.666L11.75 18.666L8 15.0297" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            BVN Match API</p>
                            <p className="text-sm">NGN 10 per Transfer</p>
                        </div>
                    </div>
                </div> */}
            </div>

        </div>
    )
}

export default PricingPage
