
// import {useEffect} from 'react';
// import Logo from '../assets/Logo1.svg';
import { Link, NavLink } from 'react-router-dom';
// import { WayaPayLogo2 } from '../assets';
import config from '../config.json';
import logo from '../assets/quickLogo.png';

function Navbar() {
    const showDropdown = () => {
        document.querySelector(".dropdown").classList.toggle("hidden");
    }
    return (
        <nav class="navbar sticky top-0 w-full z-20 px-8 md:px-16 flex" style={{background:'#FF6700'}}>
            <Link to="/" class="navbar-brand">
                <img src={logo} alt="" width="200px" height= "100px"  /> 
                
            </Link>
            <div class="nav items-center gap-6 -ml-8 hidden md:flex">
                <NavLink to="/solution" exact activeStyle={{ borderBottom: "2px solid #FFFFFF", backgroundColor: "transparent" }} className="font-semibold text-md">OUR FEATURES</NavLink>
                <NavLink to="/contact" exact activeStyle={{ borderBottom: "2px solid #FFFFFF", backgroundColor: "transparent" }} className="font-semibold text-md">CONTACT</NavLink>
                <NavLink to="/get-started" exact activeStyle={{ borderBottom: "2px solid #FFFFFF", backgroundColor: "transparent" }} className="font-semibold text-md">DOCS</NavLink>
                <NavLink to="/pricing" exact activeStyle={{ borderBottom: "2px solid #FFFFFF", backgroundColor: "transparent" }} className="font-semibold text-md">PRICING</NavLink>
            </div>
            <div class="items-center gap-6 hidden md:flex">
                <a href={`${config.CUSTOMER_WEBSITE_URL}/login`}>
                    <button class="text-white font-semibold">LOGIN</button>
                </a>
                <a href={`${config.CUSTOMER_WEBSITE_URL}/register`}>
                    <button class="bg-white py-3 px-8 rounded text-primary-theme font-semibold">REGISTER</button>
                </a>
            </div>
            <div className="flex md:hidden">
                <span className="menu-btn relative w-6 h-1 bg-white block" onClick={showDropdown}></span>

                <div className="dropdown absolute w-full left-0 top-24 z-20 bg-primary-theme flex hidden sm:hidden flex-col h-auto rounded shadow">
                    <Link to="/solution" className="font-medium px-4 text-md text-white border-b border-white h-12 flex items-center">OUR FEATURES</Link>
                    <Link to="/contact" className="font-medium px-4 text-md text-white border-b border-white h-12 flex items-center">CONTACT</Link>
                    <a href={`${config.CUSTOMER_WEBSITE_URL}/login`} className="font-medium px-4 text-md text-white border-b border-white h-12 flex items-center">LOGIN</a>
                    <a href={`${config.CUSTOMER_WEBSITE_URL}/register`} className="font-medium px-4 text-md text-white h-12 flex items-center">REGISTER</a>
                </div>
            </div>
        </nav>
    )
}               


export default Navbar
