
import { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import config from '../config.json';
import { Link } from 'react-router-dom';

function Hero2() {
    useEffect(() => {
        AOS.init({
            duration: 1500
        });
    }, []);

    return (
        <div class="bg-light-theme w-full">
            <div class="px-8 md:pl-16 md:pb-16 lg:px-64 pt-24">
                <h3 data-aos="fade-up" class="hero-title text-center font-semibold leading-tight text-primary-theme">Accelerate your business with WayaQuick payment Gateway</h3>
                <p data-aos="fade-up" data-aos-delay="300" className="mt-5 lg:px-20 text-center text-lg">We have built a solution that allows you to collect payment from your customers online and offline with very fast settlement and top-notch security.</p>
                <div class="btn-container mt-8 space-y-4 flex flex-col md:flex-row md:items-center rounded lg:justify-center">
                    <a href={`${config.CUSTOMER_WEBSITE_URL}` + "/register"}>
                        <button data-aos="fade-right" class="btn-register2 md:mt-4 rounded text-sm shadow">
                            REGISTER NOW
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="white" />
                            </svg>
                        </button>
                    </a>
                    <Link to="/get-started">
                        <button data-aos="fade-up" class="explore-btn rounded text-sm font-medium rounded shadow uppercase">View documentation</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Hero2
