import React from 'react';
import config from "../config.json";
import { Link } from 'react-router-dom';


function ProductList({ image, title, subtitle, type }) {
    return (
        <div className="grid grid-cols-1 px-8 lg:px-0 lg:grid-cols-2 py-20 ">
            {
                type === "1"

                    ?

                    <React.Fragment>
                        <div className="flex items-center lg:pl-52">
                            <img src={image} alt="" width="75%" />
                        </div>
                        <div className="flex flex-col lg:justify-center lg:pr-52">
                            <h5 className="text-2xl font-semibold mb-4">{title}</h5>
                            <p className="text-lg text-gray-500 leading-6">{subtitle}</p>

                            <div class="btn-container space-y-4 mt-3 flex flex-col md:flex-row md:items-center rounded">
                                <a href={`${config?.CUSTOMER_WEBSITE_URL}/register`}>
                                    <button data-aos="fade-right" class="btn-register2 md:mt-4 rounded text-sm shadow">
                                        REGISTER NOW
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="white" />
                                        </svg>
                                    </button>
                                </a>
                                <Link to="/get-started">
                                    <button data-aos="fade-right" class="explore-btn rounded text-sm font-medium rounded shadow uppercase">Explore Docs</button>
                                </Link>
                            </div>
                        </div>
                    </React.Fragment>

                    :

                    <React.Fragment>
                        <div className="flex flex-col lg:justify-center lg:pl-52">
                            <h5 className="text-2xl font-semibold mb-4">{title}</h5>
                            <p className="text-lg text-gray-500 leading-6">{subtitle}</p>

                            <div class="btn-container space-y-4 mt-3 flex flex-col md:flex-row md:items-center rounded">
                                <a href={`${config?.CUSTOMER_WEBSITE_URL}/register`}>
                                    <button data-aos="fade-right" class="btn-register2 md:mt-4 rounded text-sm shadow">
                                        REGISTER NOW
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="white" />
                                        </svg>
                                    </button>
                                </a>
                                <Link to="/get-started">
                                    <button data-aos="fade-right" class="explore-btn rounded text-sm font-medium rounded shadow uppercase">Explore Docs</button>
                                </Link>
                            </div>
                        </div>
                        <div className="flex items-center lg:justify-end lg:pr-52">
                            <img src={image} alt="" width="75%" />
                        </div>
                    </React.Fragment>
            }
        </div>
    )
}

export default ProductList
